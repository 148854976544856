import React from 'react';
import {createStyles, Divider, Drawer, List, ListItem, ListItemText, makeStyles, Theme} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import Introduction from '../screens/Introduction';
import Connecting from '../screens/Connecting';
import Errors from '../screens/Errors';
import LocationGroups from '../screens/LocationGroups';
import Login from '../screens/Login';
import PurchaseOrders from '../screens/PurchaseOrders';
import Memos from '../screens/Memos';
import Users from '../screens/Users';
import Part from '../screens/Part';
import ManufactureOrders from '../screens/ManufactureOrders';
import Uoms from '../screens/Uoms';
import Vendors from '../screens/Vendors';
import Inventory from '../screens/Inventory';
import Product from "../screens/Product";
import Integrations from "../screens/Integrations";
import ImportExport from "../screens/ImportExport";


interface MenuData {
    name: string,
    route: string
}

const setupData: MenuData[] = [
    {
        name: 'Introduction',
        route: Introduction.path,
    },
    {
        name: 'Connecting',
        route: Connecting.path,
    },
    {
        name: 'Errors',
        route: Errors.path,
    },
];

const loginData: MenuData[] = [
    {
        name: 'Login',
        route: Login.path,
    },
];

const apiCallData: MenuData[] = [
    {
        name: "Imports and Exports",
        route: ImportExport.path,
    },
    {
        name: 'Integrations',
        route: Integrations.path,
    },
    {
        name: 'Inventory',
        route: Inventory.path,
    },
    {
        name: 'Location Groups',
        route: LocationGroups.path,
    },
    {
        name: 'Manufacture Orders',
        route: ManufactureOrders.path,
    },
    {
        name: 'Memos',
        route: Memos.path,
    },
    {
        name: 'Parts',
        route: Part.path,
    },
    {
        name: 'Products',
        route: Product.path,
    },
    {
        name: 'Purchase Orders',
        route: PurchaseOrders.path,
    },
    {
        name: 'Unit of Measure',
        route: Uoms.path,
    },
    {
        name: 'Users',
        route: Users.path,
    },
    {
        name: 'Vendors',
        route: Vendors.path,
    },
];

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        offset: theme.mixins.toolbar
    }),
);

const SideMenu: React.FC = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleClick = (url: string) => {
        if (!url.includes('#')) {
            window.scrollTo(0, 0);
        }

        history.push(url);
    };

    const {pathname} = useLocation();

    const makeListItems = (listItems: MenuData[]) =>
        listItems.map(({ name, route }) => (
            <ListItem button key={ name } selected={ route === pathname } onClick={ () => {
                handleClick(route);
            } }>
                <ListItemText primary={ name }/>
            </ListItem>
        ));

    return (
        <Drawer
            className={ classes.drawer }
            variant="permanent"
            classes={ {
                paper: classes.drawerPaper,
            } }
        >
            <div className={ classes.offset }/>
            <div className={ classes.drawerContainer }>
                <List>
                    { makeListItems(setupData) }
                </List>
                <Divider/>

                <List>
                    { makeListItems(loginData) }
                </List>
                <Divider/>
                <List>
                    { makeListItems(apiCallData) }
                </List>
            </div>
        </Drawer>
    );
};

export default SideMenu;
