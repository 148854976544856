import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {jsonObject, pluginInfoAttributes, pluginInfoHeader} from '../../data/integrations/pluginInfo';
import getPluginInfoRequest from '../../data/integrations/getPluginInfoRequest';
import createPluginInfoRequest from '../../data/integrations/createPluginInfoRequest';
import deletePluginInfoRequest from '../../data/integrations/deletePluginInfoRequest';
import updatePluginInfoRequest from '../../data/integrations/updatePluginInfoRequest';
import ObjectDefinition from '../ObjectDefinition';

export default new RouteComponent(`${apiRoutePrefix}/integrations`, () => {
    const requests = [
        getPluginInfoRequest,
        createPluginInfoRequest,
        updatePluginInfoRequest,
        deletePluginInfoRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ pluginInfoHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The plugin information object',
                attributes: pluginInfoAttributes,
                jsonObject: jsonObject
            } }/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
