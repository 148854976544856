import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider, Typography} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import ObjectDefinition from '../ObjectDefinition';
import RequestDefinition from '../RequestDefinition';
import {jsonObject, memoAttributes, memosHeader} from '../../data/memos/memo';

import getMemoRequest from '../../data/memos/getMemoRequest';
import getMemoListRequest from '../../data/memos/getMemoListRequest';
import createMemoRequest from '../../data/memos/createMemoRequest';
import updateMemoRequest from '../../data/memos/updateMemoRequest';
import deleteMemoRequest from '../../data/memos/deleteMemoRequest';

export default new RouteComponent(`${apiRoutePrefix}/memos`, () => {

    const requests = [
        getMemoListRequest,
        getMemoRequest,
        createMemoRequest,
        updateMemoRequest,
        deleteMemoRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ memosHeader }>
                <Box>
                    <Typography>The following objects support memos:</Typography>
                    <ul>
                        <li><Typography>Manufacture Orders</Typography></li>
                        <li><Typography>Purchase Orders</Typography></li>
                    </ul>
                </Box>
            </ObjectHeader>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The memo object',
                attributes: memoAttributes,
                jsonObject: jsonObject
            } }/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data}/>
                    </>
                ))
            }

        </div>
    );
});
