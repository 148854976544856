import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import {loginHeader} from '../../data/login/login';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';
import loginRequest from '../../data/login/loginRequest';
import logoutRequest from '../../data/logout/logoutRequest';

export default new RouteComponent(`${apiRoutePrefix}/login`,() => {

    const requests = [loginRequest, logoutRequest];
    return (
        <div>
            <ObjectHeader data={loginHeader}/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data}/>
                    </>
                ))
            }
        </div>
    );
});
