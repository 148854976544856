import React from 'react';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import HomeScreen from './components/home/HomeScreen';
import DocumentationScreen from './components/documentation/DocumentationScreen';
import createFbTheme from './FbTheme';
import './index.css';

const App: React.FC = () => {

    const theme = createFbTheme({
        palette: {
            type: 'dark',
            secondary: {
                main: '#88c4d1'
            },
        },

    });

    return (
        <ThemeProvider theme={ theme }>
            <CssBaseline/>
            <BrowserRouter>
                <Switch>
                    <Route {...DocumentationScreen}/>
                    <Route {...HomeScreen}/>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
