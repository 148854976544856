import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const request: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/location-groups',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/location-groups' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const response: Json = {
    title: 'RESPONSE',
    json: {
        "totalCount": 1,
        "totalPages": 1,
        "pageNumber": 1,
        "pageSize": 100,
        "results": [
            {
                "id": 1,
                "name": "Each",
                "abbreviation": "ea",
                "description": "A single item.",
                "type": "Count",
                "active": true
            }
        ]
    },
};

const queryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'name',
        type: 'string',
        description: 'The location group name.',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'The active status of the location groups.',
    },
];

const attributes: Attribute[] = [];

const data: RequestDefinitionData = {
    callName: 'search-location-group',
    title: 'Search for location groups',
    description: 'Searches for location groups.',
    attributes: attributes,
    requestObject: request,
    responseObject: response,
    queryParameters: queryParameters,
}

export default data;
