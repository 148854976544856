import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const searchPartsRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/parts',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/parts' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const searchPartsResponse: Json = {
    title: 'RESPONSE',
    json: {
        'totalCount': 2,
        'totalPages': 1,
        'pageNumber': 1,
        'pageSize': 100,
        'results': [
            {
                'id': 29,
                'number': 'FG2100',
                'description': 'Extreme Mountain Bike',
                'type': 'Inventory',
                'uom': {
                    'id': 1,
                    'name': 'Each',
                    'abbreviation': 'ea'
                },
                'upc': '',
                'active': true,
                'hasBom': true,
                'hasTracking': true,
                'tracksSerialNumbers': true
            },
            {
                'id': 31,
                'number': 'FG2200',
                'description': 'Custom Extreme Mountain Bike',
                'type': 'Inventory',
                'uom': {
                    'id': 1,
                    'name': 'Each',
                    'abbreviation': 'ea'
                },
                'upc': '',
                'active': true,
                'hasBom': true,
                'hasTracking': true,
                'tracksSerialNumbers': true
            }
        ]
    },
};

const searchPartsQueryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'number',
        type: 'string',
        description: 'The part number.',
    },
    {
        name: 'description',
        type: 'string',
        description: 'The part description.',
    },
    {
        name: 'upc',
        type: 'string',
        description: 'The UPC code for the part.',
    },
    {
        name: 'type',
        type: 'string: \'Inventory\' | \'Service\' | \'Labor\' | \'Overhead\' | \'Non-Inventory\' | \'Internal Use\' | \'Capital Equipment\' | \'Shipping\'',
        description: 'The basic type of the part.',
    },
    {
        name: 'abc',
        type: 'string',
        description: 'The ABC code for the part',
    },
    {
        name: 'details',
        type: 'string',
        description: 'The part details.',
    },
    {
        name: 'hasBom',
        type: 'boolean',
        description: 'Indicates if the part has an associated bill of materials. True returns parts with an associated bill of materials, false does not filter the results.',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'The active status of the UOM.',
    },
    {
        name: 'productNumber',
        type: 'string',
        description: 'The associated product number.',
    },
    {
        name: 'productDescription',
        type: 'string',
        description: 'The product description.',
    },
    {
        name: 'vendorPartNumber',
        type: 'string',
        description: 'The vendor part number.',
    },
    {
        name: 'vendorName',
        type: 'string',
        description: 'The name of the associated vendor.',
    },
];

const searchPartsAttributes: Attribute[] = [
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the part\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the custom field.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    }
];

const data: RequestDefinitionData = {
    callName: 'search-part',
    title: 'Search for parts',
    description: 'Searches for parts.',
    attributes: searchPartsAttributes,
    requestObject: searchPartsRequest,
    responseObject: searchPartsResponse,
    queryParameters: searchPartsQueryParameters,
}

export default data;
