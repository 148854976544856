import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';
import {Attribute} from '../../components/ObjectAttribute';

const loginRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/login',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/login' \\
--header 'Content-Type: application/json' \\
--data-raw '{
    "appName": "App Name",
    "appDescription": "my description",
    "appId": 12345,
    "username": "JohnSmith",
    "password": "password12345"
}'`
    ,
};

const loginResponse: Json = {
    title: 'RESPONSE',
    json: {
        'token': '7dd6bcf7-5e92-4c25-86c9-54af4a831d54',
        'user': {
            'id': 5,
            'userFullName': 'John Smith',
            'moduleAccessList': [
                'Company',
                'Customer',
                'Payment Receipt',
                'Dashboard',
                'Product',
                'Home',
                'Property',
                'Custom Field',
                'Receiving',
                'Payment Gateway',
                'Calendar',
                'RMA',
                'Reports',
                'Data',
                'Sales Order Invoice',
                'Vendor',
                'Location Group',
                'UOM',
                'Part Label',
                'Schedule',
                'Memo',
                'Location',
            ],
            'serverVersion': '20.11.20201111'
        }
    },
};

const loginAttributes: Attribute[] = [
    {
        name: 'appName',
        type: 'string',
        description: 'The name of your application.'
    },
    {
        name: 'appDescription',
        type: 'string',
        description: 'A brief description of what your application does.'
    },
    {
        name: 'appId',
        type: 'integer',
        description: 'The unique identifier for your integrated application. Use any number you want, but we recommend at least 4 digits in length.'
    },
    {
        name: 'username',
        type: 'string',
        description: 'The username of the user logging in.'
    },
    {
        name: 'password',
        type: 'string',
        description: 'The user\'s password.'
    },
]

const data: RequestDefinitionData = {
    callName: 'login',
    title: 'Logging in to Fishbowl',
    wikiLink: 'https://www.fishbowlinventory.com/wiki/Settings#Integrated_Apps_tab',
    wikiLinkName: 'Approving Integrations',
    description: 'This logs the user in to Fishbowl and returns the list of Access Rights. ' +
        'The first time your Integrated App logs in, you\'ll need to approve the App in Fishbowl.',
    attributes: loginAttributes,
    requestObject: loginRequest,
    responseObject: loginResponse,
}

export default data;
