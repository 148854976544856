import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {productHeader} from '../../data/product/product';
import getProductBestPriceRequest from '../../data/product/getProductBestPrice';

export default new RouteComponent(`${apiRoutePrefix}/products`, () => {

    return (
        <div>
            <ObjectHeader data={ productHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <RequestDefinition {...getProductBestPriceRequest}/>
        </div>
    );
});
