import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const getPartBestCostRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/parts/:id/best-cost',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/parts/:id/best-cost' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const getPartBestCostResponse: Json = {
    title: 'RESPONSE',
    json: {
        'totalCost': '5',
        'unitCost': '5',
        'quantity': '1',
        'minimumQuantity': '0',
        'uom': {
            'id': 1,
            'name': 'Each',
            'abbreviation': 'ea'
        },
        'vendorPartNum': 'B201'
    }
};

const getPartBestCostQueryParameters: Attribute[] = [
    {
        name: 'vendorId',
        type: 'integer',
        description: 'The unique identifier for the vendor associated with the part.'
    },
    {
        name: 'quantity',
        type: 'quantity',
        description: 'The part quantity associated with the cost.'
    },
    {
        name: 'uomId',
        type: 'integer',
        description: 'The unique identifier for the unit of measure.'
    }

];
const data: RequestDefinitionData = {
    callName: 'get-part-best-cost',
    title: 'Get the best cost for the part',
    description: 'Retrieves the best cost for a specific part. The cost will be in the currency of the vendor.',
    attributes: [],
    requestObject: getPartBestCostRequest,
    responseObject: getPartBestCostResponse,
    queryParameters: getPartBestCostQueryParameters,
    wikiLinkName: 'Vendor Part Cost',
    wikiLink: 'https://www.fishbowlinventory.com/wiki/Vendor#Associate_a_part_with_a_vendor',
};

export default data;
