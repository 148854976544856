import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const manufactureOrdersEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/manufacture-orders',
        link: '#search-manufacture-order',
        tooltip: 'Search for manufacture orders',
    },
    {
        type: 'GET',
        endpoint: '/manufacture-orders/:id',
        link: '#get-manufacture-order',
        tooltip: 'Get a manufacture order',
    },
    {
        type: 'POST',
        endpoint: '/manufacture-orders',
        link: '#create-manufacture-order',
        tooltip: 'Create a manufacture order',
    },
    {
        type: 'POST',
        endpoint: '/manufacture-orders/:id',
        link: '#update-manufacture-order',
        tooltip: 'Update a manufacture order',
    },
    {
        type: 'POST',
        endpoint: '/manufacture-orders/:id/issue',
        link: '#issue-manufacture-order',
        tooltip: 'Issue a manufacture order',
    },
    {
        type: 'POST',
        endpoint: '/manufacture-orders/:id/unissue',
        link: '#unissue-manufacture-order',
        tooltip: 'Unissue a manufacture order',
    },
    {
        type: 'POST',
        endpoint: '/manufacture-orders/:id/close-short',
        link: '#close-short-manufacture-order',
        tooltip: 'Close short a manufacture order',
    },
    {
        type: 'DELETE',
        endpoint: '/manufacture-orders/:id',
        link: '#delete-manufacture-order',
        tooltip: 'Delete a manufacture order',
    }
];

export const manufactureOrdersHeader: HeaderData = {
    title: 'Manufacture Orders',
    description: 'This is an object representing a Fishbowl manufacture order. Manufacture orders are used ' +
        'to organize work orders and allows for items to be manufactured, disassembled, and repaired.',
    linkName: 'Manufacture Orders',
    link: 'https://www.fishbowlinventory.com/wiki/Manufacture_Order',
    endpoints: manufactureOrdersEndpoints,
};

export const jsonObject: Json = {
    title: 'THE MANUFACTURE ORDER OBJECT',
    json: {
        "id": 5,
        "number": "1006",
        "revisionNumber": 2,
        "note": "",
        "locationGroup": {
            "id": 5,
            "name": "SLC"
        },
        "dateCreated": "2021-04-10T18:09:26.823-0600",
        "lastModified": {
            "username": "admin",
            "dateLastModified": "2021-05-01T18:34:01.436-0600"
        },
        "dateScheduled": "2021-04-10T18:09:26.705-0600",
        "url": "",
        "percentComplete": "0%",
        "status": "Entered",
        "class": {
            "id": 1,
            "name": "None"
        },
        "configurations": [
            {
                "id": 53,
                "bom": {
                    "id": 7,
                    "name": "BK100"
                },
                "priceAdjustment": "0",
                "unitCost": "0",
                "dateScheduled": "2021-04-10T18:09:26.000-0600",
                "dateScheduledToStart": "2021-04-10T18:09:26.000-0600",
                "sortId": 1,
                "stageLevel": 1,
                "hasSo": false,
                "status": "Entered",
                "quantity": "15",
                "quantityFulfilled": "0",
                "class": {
                    "id": 1,
                    "name": "None"
                },
                "priority": "3-Normal",
                "items": [
                    {
                        "id": 54,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 1,
                        "part": {
                            "id": 80,
                            "name": "BK100",
                            "description": "New to Biking Kit"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "15",
                        "quantityFulfilled": "0",
                        "type": "Finished Good"
                    },
                    {
                        "id": 55,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 2,
                        "part": {
                            "id": 52,
                            "name": "PM100",
                            "description": "Entry Bike Pump"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "15",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    },
                    {
                        "id": 56,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 3,
                        "part": {
                            "id": 77,
                            "name": "H100",
                            "description": "Basic Bike Helmet"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "15",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    },
                    {
                        "id": 57,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 4,
                        "part": {
                            "id": 56,
                            "name": "PK100",
                            "description": "Patch Kit"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "15",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    }
                ]
            }
        ],
        "customFields": [
            {
                "id": 25,
                "name": "Custom",
                "type": "Text"
            },
            {
                "id": 29,
                "name": "Type",
                "type": "Drop-Down List"
            }
        ]
    },
};

export const manufactureOrderAttributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The manufacture order\'s unique identification number.'
    },
    {
        name: 'number',
        type: 'string',
        description: 'The manufacture order number.'
    },
    {
        name: 'status',
        type: 'string',
        description: 'The order status.'
    },
    {
        name: 'revisionNumber',
        type: 'integer',
        description: 'The revision number.',
    },
    {
        name: 'note',
        type: 'string',
        description: 'The order\'s note field.',
    },
    {
        name: 'locationGroup',
        type: 'reference object',
        description: 'The location group the order belongs to.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'salesOrder',
        type: 'reference object',
        description: 'The associated sales order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'dateCreated',
        type: 'date',
        description: 'Timestamp of when the order was created.',
    },
    {
        name: 'lastModified',
        type: 'object',
        description: 'Timestamp of when the order was last modified and the user that made the modifications.',
        children: [
            {
                name: 'username',
                type: 'string',
                description: 'The username of the user last modified.',
            },
            {
                name: 'dateLastModified',
                type: 'date',
                description: 'The date last modified.',
            }
        ]
    },
    {
        name: 'dateIssued',
        type: 'date',
        description: 'Timestamp of when the order was issued.',
    },
    {
        name: 'dateScheduled',
        type: 'date',
        description: 'Timestamp of when the order was scheduled.',
    },
    {
        name: 'dateCompleted',
        type: 'date',
        description: 'Timestamp of when the order was completed.',
    },
    {
        name: 'url',
        type: 'string',
        description: 'The url link on the order.',
    },
    {
        name: 'percentComplete',
        type: 'string',
        description: 'The percent of the order that is complete.'
    },
    {
        name: 'class',
        type: 'reference object',
        description: 'The class category.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'configurations',
        type: 'list',
        description: 'A list of the manufacture order configurations.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The configuration\'s unique identification number.'
            },
            {
                name: 'status',
                type: 'string',
                description: 'The order status.'
            },
            {
                name: 'description',
                type: 'string',
                description: 'The description of the object.'
            },
            {
                name: 'bom',
                type: 'reference object',
                description: 'The bill of materials associated with the configuration.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.'
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.'
                    },
                ]
            },
            {
                name: 'quantity',
                type: 'quantity',
                description: 'The quantity to be fulfilled.',
            },
            {
                name: 'quantityFulfilled',
                type: 'quantity',
                description: 'The quantity already fulfilled.',
            },
            {
                name: 'priceAdjustment',
                type: 'money',
                description: 'The amount to adjust the configuration price on the sales order.'
            },
            {
                name: 'unitCost',
                type: 'money',
                description: 'The unit cost of the configuration.'
            },
            {
                name: 'dateScheduled',
                type: 'date',
                description: 'Timestamp of when the configuration was scheduled.',
            },
            {
                name: 'dateScheduledToStart',
                type: 'date',
                description: 'Timestamp of when the configuration was scheduled to start.',
            },
            {
                name: 'note',
                type: 'string',
                description: 'The item\'s note field.',
            },
            {
                name: 'sortId',
                type: 'integer',
                description: 'The position in the sort order.'
            },
            {
                name: 'stageLevel',
                type: 'integer',
                description: 'The depth of the stage.'
            },
            {
                name: 'class',
                type: 'reference object',
                description: 'The class category.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    }
                ]
            },
            {
                name: 'hasSo',
                type: 'boolean',
                description: 'Indicates if the configuration has an associated sales order.'
            },
            {
                name: 'priority',
                type: 'string',
                description: 'The name of the priority level.'
            },
            {
                name: 'category',
                type: 'reference object',
                description: 'The calendar category.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.'
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.'
                    },
                ]
            },
            {
                name: 'items',
                type: 'list',
                description: 'The list of configuration items.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The item\'s unique identification number.'
                    },
                    {
                        name: 'status',
                        type: 'string',
                        description: 'The order status.'
                    },
                    {
                        name: 'description',
                        type: 'string',
                        description: 'The description of the object.'
                    },
                    {
                        name: 'quantity',
                        type: 'quantity',
                        description: 'The quantity to be fulfilled.',
                    },
                    {
                        name: 'quantityFulfilled',
                        type: 'quantity',
                        description: 'The quantity already fulfilled.',
                    },
                    {
                        name: 'stage',
                        type: 'boolean',
                        description: 'Indicates if the item is a stage.'
                    },
                    {
                        name: 'priceAdjustment',
                        type: 'money',
                        description: 'The amount to adjust the item price on the sales order.'
                    },
                    {
                        name: 'unitCost',
                        type: 'money',
                        description: 'The unit cost of the item.'
                    },
                    {
                        name: 'dateScheduled',
                        type: 'date',
                        description: 'Timestamp of when the item was scheduled.',
                    },
                    {
                        name: 'dateScheduledToStart',
                        type: 'date',
                        description: 'Timestamp of when the item was scheduled to start.',
                    },
                    {
                        name: 'note',
                        type: 'string',
                        description: 'The item\'s note field.',
                    },
                    {
                        name: 'sortId',
                        type: 'integer',
                        description: 'The position in the sort order.'
                    },
                    {
                        name: 'part',
                        type: 'reference object',
                        description: 'The part associated with the item.',
                        children: [
                            {
                                name: 'id',
                                type: 'integer',
                                description: 'The unique identification number.'
                            },
                            {
                                name: 'name',
                                type: 'string',
                                description: 'The name of the object.'
                            },
                        ]
                    },
                    {
                        name: 'type',
                        type: 'string: \'Finished Good\' | \'Raw Good\' | \'Repair Raw Good\' | \'Note\' | \'Bill of Materials\' |',
                        description: 'The configuration item type.',
                    },
                    {
                        name: 'uom',
                        type: 'reference object',
                        description: 'The configuration item\'s unit of measure.',
                        children: [
                            {
                                name: 'id',
                                type: 'integer',
                                description: 'The unique identification number.',
                            },
                            {
                                name: 'name',
                                type: 'string',
                                description: 'The name of the object.',
                            },
                            {
                                name: 'abbreviation',
                                type: 'string',
                                description: 'The abbreviated name.',
                            }
                        ]
                    },
                    {
                        name: 'oneTimeItem',
                        type: 'boolean',
                        description: 'Indicates if the item is a one time item.',
                    },
                    {
                        name: 'configuration',
                        type: 'object',
                        description: 'An optional nested configuration object.',
                    },
                ]
            },
        ]
    },
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of custom fields associated with the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The custom field\'s unique identifier.',

            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the custom field.',
            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
            {
                name: 'type',
                type: 'string',
                description: 'The category of the custom field\'s value.'
            },
        ]
    },
];
