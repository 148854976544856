import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';

export interface RequestExample {
    title: {
        verb: string,
        endpointUrl: string,
    },
    curl: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: '10px',
        },
        paperHeader: {
            backgroundColor: 'grey',
            borderRadius: '10px 10px 0 0',
            paddingLeft: '.9rem'
        },
        paperContent: {
            padding: '.5rem',
            maxHeight: '88vh',
            overflowY: 'auto',
        },
    })
);

interface Props {
    requestExample: RequestExample,
}

const RequestExampleCard: React.FC<Props> = ({ requestExample: { title, curl } }) => {
    const classes = useStyles();

    return (
        <Paper variant="outlined" className={ classes.paper }>
            <div className={ classes.paperHeader }>
                <Typography variant="subtitle1"><span
                    className={ title.verb }>{ title.verb }</span> { title.endpointUrl }</Typography>
            </div>
            <div className={ classes.paperContent }>
                <pre>
                    { curl }
                </pre>
            </div>
        </Paper>
    );
};

export default RequestExampleCard;
