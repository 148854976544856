import React from 'react';

export const apiRoutePrefix = '/apidocs';

export class RouteComponent {
    path: string;
    component: React.FC;

    constructor(route: string, component: React.FC) {
        this.path = route;
        this.component = component;
    }
}