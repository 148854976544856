import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const updateMemoRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/<OBJECT ENDPOINT>/:id/memos/:memoId',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/<OBJECT ENDPOINT>/:id/memos/:memoId' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<MEMO JSON>'
`,
};
const updateMemoResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 42,
        "memo": "Updated memo",
        "username": "admin",
        "dateCreated": "2021-02-26T13:59:53.171-0700"
    }
};

const updateMemoAttributes: Attribute[] = [
    {
        name: 'memo',
        type: 'string',
        optional: false,
        description: 'The text inside the memo.'
    },
];

const data: RequestDefinitionData = {
    callName: 'update-memo',
    title: 'Update a memo',
    description: 'Updates a memo.',
    attributes: updateMemoAttributes,
    requestObject: updateMemoRequest,
    responseObject: updateMemoResponse,
}

export default data;
