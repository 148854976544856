import React, {useState} from 'react';
import {createStyles, Divider, List, ListItem, makeStyles, Theme, Typography} from '@material-ui/core';
import {Add as AddIcon, Close as CloseIcon} from '@material-ui/icons';

export interface Attribute {
    name: string,
    type: string,
    optional?: boolean,
    description: string,
    children?: Attribute[],
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        name: {
            fontWeight: 'bold',
        },
        type: {
            color: theme.palette.text.secondary,
        },
        spacing: {
            marginBottom: '0.5rem',
        },
        divider: {
            margin: '0.5rem 0',
        },
        optional: {
            fontStyle: 'italic',
        },
        paper: {
            borderRadius: '10px',
            border: '1px solid rgba(255, 255, 255, 0.12)'
        },
        paperHeader: {
            borderRadius: '10px 10px 0 0',
            padding: '.3rem .9rem 0 .9rem',
        },
        paperContent: {
            padding: '.5rem',
            maxHeight: '88vh',
            overflowY: 'auto',
        },
        headerText: {
            color: theme.palette.text.secondary,
            '&:hover': {
                color: theme.palette.text.primary,
                cursor: 'pointer',
            },
        },
        icon: {
            verticalAlign: '-2px',
            marginRight: '.3rem',
        },
        noAttributes: {
            color: theme.palette.text.secondary,
        },
    }),
);

interface Props {
    attribute: Attribute,
}

const ObjectAttribute: React.FC<Props> = ({ attribute: { name, type, optional, description, children } }) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Divider className={ classes.divider }/>

            <ListItem>
                <div>
                    <div className={ classes.spacing }>
                        <Typography className={ classes.name } component="span">{ name }</Typography> <Typography
                        className={ classes.type } component="span">{ type }
                        <span className={ classes.optional }>{ optional && ' optional' }</span></Typography>
                    </div>
                    <div>
                        <Typography variant={ 'body2' } component="span">{ description }</Typography>
                    </div>
                    { children && <List>
                        <div className={ classes.paper }>
                            <div className={ classes.paperHeader } onClick={ toggleExpand }>
                                <Typography variant="subtitle1" className={ classes.headerText }>
                                    { expanded
                                        ? <><CloseIcon fontSize="inherit" className={ classes.icon }/>Hide child
                                            attributes</>
                                        : <><AddIcon fontSize="inherit" className={ classes.icon }/>Show child
                                            attributes</> }
                                </Typography>
                            </div>
                            { expanded &&
                            <div className={ classes.paperContent }>
                                { children.length > 0
                                    ? children.map(attribute => <ObjectAttribute key={ attribute.name }
                                                                                 attribute={ attribute }/>)
                                    : <Typography className={ classes.noAttributes }>No attributes.</Typography>
                                }
                            </div>
                            }
                        </div>
                    </List> }
                </div>
            </ListItem>
        </>
    );
};

export default ObjectAttribute;
