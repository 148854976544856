import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const integrationsEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/integrations/plugin-info',
        link: '#get-plugin-info',
        tooltip: 'Search for plugin information',
    },
    {
        type: 'POST',
        endpoint: '/integrations/plugin-info',
        link: '#create-plugin-info',
        tooltip: 'Create a plugin information',
    },
    {
        type: 'POST',
        endpoint: '/integrations/plugin-info/:id',
        link: '#update-plugin-info',
        tooltip: 'Update plugin information',
    },
    {
        type: 'DELETE',
        endpoint: '/integrations/plugin-info/:id',
        link: '#delete-plugin-info',
        tooltip: 'Delete plugin information',
    },
];

export const pluginInfoHeader: HeaderData = {
    title: 'Integrations',
    description: 'These endpoints are used to modify Fishbowl plugin information. In order to access the plugin\'s ' +
        'information, you will need authorization from the plugin.',
    endpoints: integrationsEndpoints,
};

export const jsonObject: Json = {
    title: 'THE PLUGIN INFORMATION OBJECT',
    json: {
        'id': 32,
        'plugin': 'Fishbowl Time',
        'table': 'WO',
        'recordId': 72,
        'groupId': 20,
        'channelId': '27d1891e-0627-4abf-87a6-9a38db5d78f2',
        'info': {
            'id': 123
        }
    },};

export const pluginInfoAttributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The plugin information\'s unique identification number.',
    },
    {
        name: 'plugin',
        type: 'string',
        description: 'The name of the plugin.',
    },
    {
        name: 'table',
        type: 'string',
        description: 'The name of the database table.',
    },
    {
        name: 'recordId',
        type: 'int',
        description: 'The unique identifier for a record in Fishbowl associated with the table.',
    },
    {
        name: 'groupId',
        type: 'int',
        description: 'The user specified identifier used to separate types of plugins you are using.',
    },
    {
        name: 'channelId',
        type: 'string',
        description: 'The unique identifier for the object being linked externally.',
    },
    {
        name: 'info',
        type: 'json',
        description: 'Any additional information.',
    },
];
