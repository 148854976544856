import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Grid, Paper, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export default new RouteComponent(`${apiRoutePrefix}/errors`, () => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            spacing: {
                [theme.breakpoints.down('sm')]: {
                    marginBottom: 0,
                }
            },
            paper: {
                borderRadius: '10px',
            },
            paperHeader: {
                backgroundColor: 'grey',
                borderRadius: '10px 10px 0 0',
                paddingLeft: '.9rem'
            },
            paperContent: {
                padding: '.5rem'
            },
            row: {
                borderBottom: 'none'
            }
        })
    );

    const classes = useStyles();

    interface CodeRow {
        code: string,
        description: string,
    }

    const rows: CodeRow[] = [
        { code: '200 - OK', description: 'Everything worked.' },
        {
            code: '400 - Bad Request',
            description: 'Could not accept the request. Possibly a missing required parameter.'
        },
        { code: '401 - Unauthorized', description: 'Invalid session key or login credentials.' },
        { code: '402 - Request Failed', description: 'The parameters were valid but the request failed.' },
        { code: '403 - Forbidden', description: 'The current user did not have the required access rights.' },
        { code: '404 - Not Found', description: 'The path does not exist.' },
        { code: '500 - Server Error', description: 'An error occurred on Fishbowl\'s end.' },
    ];

    return (
        <>
            <Box mb={ 3 }>
                <Typography variant="h4">Errors</Typography>
            </Box>
            <Grid container spacing={ 3 }>
                <Grid item md={ 6 } xs={ 12 }>
                    <Box mb={ 3 }>
                        <Typography>
                            Fishbowl uses standard HTTP response codes to indicate the status of the API request.
                            200 range response codes mean success. 400 range response codes mean failure.
                        </Typography>
                        <Box mb={ 2 } />
                        <Typography>
                            Most errors will return a user friendly explanation of the error cause.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={ 6 } xs={ 12 }>
                    <Box mb={ 3 }>
                        <Paper variant="outlined" className={ classes.paper }>
                            <div className={ classes.paperHeader }>
                                <Typography variant="subtitle1">HTTP STATUS CODES</Typography>
                            </div>
                            <div className={ classes.paperContent }>
                                <Table>
                                    <TableBody>
                                        { rows.map((row) => (
                                            <TableRow key={ row.code }>
                                                <TableCell style={ { width: '30%' } } component="th" scope="row"
                                                           className={ classes.row }>
                                                    { row.code }
                                                </TableCell>
                                                <TableCell style={ { width: '70%' } }
                                                           className={ classes.row }>{ row.description }</TableCell>
                                            </TableRow>
                                        )) }
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

        </>
    );
});
