import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const memosEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/<OBJECT ENDPOINT>/:id/memos',
        link: '#get-memo-list',
        tooltip: 'Get a list of memos',
    },
    {
        type: 'GET',
        endpoint: '/<OBJECT ENDPOINT>/:id/memos/:memoId',
        link: '#get-memo',
        tooltip: 'Get a memo',
    },
    {
        type: 'POST',
        endpoint: '/<OBJECT ENDPOINT>/:id/memos',
        link: '#create-memo',
        tooltip: 'Create a memo',
    },
    {
        type: 'POST',
        endpoint: '/<OBJECT ENDPOINT>/:id/memos/:memoId',
        link: '#update-memo',
        tooltip: 'Update a memo',
    },
    {
        type: 'DELETE',
        endpoint: '/<OBJECT ENDPOINT>/:id/memos/:memoId',
        link: '#delete-memo',
        tooltip: 'Delete a memo',
    }
];

export const memosHeader: HeaderData = {
    title: 'Memo',
    description: 'This is an object representing a Fishbowl memo. Memos are used to add information to fishbowl objects.',
    linkName: 'Memos',
    link: 'https://www.fishbowlinventory.com/wiki/Purchase_Order#Memo_tab',
    endpoints: memosEndpoints,
};

export const jsonObject: Json = {
    title: 'THE MEMO OBJECT',
    json: {
        "id": 42,
        "memo": "This is a memo",
        "username": "admin",
        "dateCreated": "2021-02-26T13:59:53.171-0700"
    }
};

export const memoAttributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The memo\'s unique identification number.'
    },
    {
        name: 'memo',
        type: 'string',
        description: 'The text inside the memo.'
    },
    {
        name: 'username',
        type: 'string',
        description: 'Username of the user who created or modified the memo.'
    },
    {
        name: 'dateCreated',
        type: 'date',
        description: 'The date the memo was created.'
    }
];
