import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const logoutRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/logout'
    },
    curl:
        `curl --location --request POST '<YOUR SERVER>/api/logout' \\
--header 'Authorization: Bearer <TOKEN>'`
    ,
};

const logoutResponse: Json = {
    title: 'RESPONSE',
    json: {},
};

const data: RequestDefinitionData = {
    callName: 'logout',
    title: 'Logging out of Fishbowl',
    description: 'This logs the user out of Fishbowl, ending the current session.',
    attributes: [],
    requestObject: logoutRequest,
    responseObject: logoutResponse,
};

export default data;
