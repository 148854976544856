import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const createMemoRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/<OBJECT ENDPOINT>/:id/memos',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/<OBJECT ENDPOINT>/:id/memos' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<MEMO JSON>'
`,
};

const createMemoResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 44,
        "memo": "Creating a memo",
        "username": "admin",
        "dateCreated": "2021-02-26T14:34:53.570-0700"
    }
};

const createMemoAttributes: Attribute[] = [
    {
        name: 'memo',
        type: 'string',
        optional: false,
        description: 'The text inside the memo.'
    },
];

const data: RequestDefinitionData = {
    callName: 'create-memo',
    title: 'Create a memo',
    description: 'Creates a memo.',
    attributes: createMemoAttributes,
    requestObject: createMemoRequest,
    responseObject: createMemoResponse,
};

export default data;