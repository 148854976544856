import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const deleteUomRequest: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/uoms/:id',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/uoms/:id' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const deleteUomResponse: Json = {
    title: 'RESPONSE',
    json: {},
};

const data: RequestDefinitionData = {
    callName: 'delete-uom',
    title: 'Delete a unit of measure',
    description: 'Deletes the unit of measure with the specified ID.',
    attributes: [],
    requestObject: deleteUomRequest,
    responseObject: deleteUomResponse,
};

export default data;
