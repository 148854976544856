import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const getUomRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/uoms/:id',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/uoms/:id' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const getUomResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 1,
        "name": "Each",
        "abbreviation": "ea",
        "description": "A single item.",
        "type": "Count",
        "active": true,
        "integral": true,
        "readOnly": true,
        "conversions": [
            {
                "id": 32,
                "description": "1 Pair = 2.0 Each",
                "targetUom": {
                    "id": 18,
                    "name": "Pair",
                    "abbreviation": "pr"
                },
                "divisor": "2",
                "multiplier": "1"
            },
            {
                "id": 34,
                "description": "1 Package = 20.0 Each",
                "targetUom": {
                    "id": 19,
                    "name": "Package",
                    "abbreviation": "pk"
                },
                "divisor": "20",
                "multiplier": "1"
            }
        ]
    },
};

const data: RequestDefinitionData = {
    callName: 'get-uom',
    title: 'Get a unit of measure',
    description: 'Retrieves the details of a unit of measure. You only need to provide the unique UOM ID.',
    attributes: [],
    requestObject: getUomRequest,
    responseObject: getUomResponse,
};

export default data;
