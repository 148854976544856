import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';
import searchVendorsRequest from '../../data/vendor/searchVendorsRequest';
import {vendorHeader} from '../../data/vendor/vendor';

export default new RouteComponent(`${apiRoutePrefix}/vendors`, () => {

    return (
        <div>
            <ObjectHeader data={ vendorHeader }/>

            {/*<Box mb={ 3 }>*/}
            {/*    <Divider/>*/}
            {/*</Box>*/}

            {/*<ObjectDefinition data={ {*/}
            {/*    title: 'The vendor object',*/}
            {/*    attributes: vendorAttributes,*/}
            {/*    jsonObject: jsonObject*/}
            {/*} }/>*/}

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <RequestDefinition {...searchVendorsRequest}/>
        </div>
    );
});
