import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';
import {Attribute} from '../../components/ObjectAttribute';

const importRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/import/:name',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/import/:name' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '"header1","header2","header3"
"data11","data12","data13"
"data21","data22","data23"'
    `,
};

const importResponse: Json = {
    title: 'RESPONSE',
    json: '',
};

const importAttributes: Attribute[] = [

]

const data: RequestDefinitionData = {
    callName: 'import',
    title: 'Import data via CSV',
    description: 'Import data via our CSV methods. Set the request Content-Type to either text/plain or text/csv. ' +
        'In the request, the name parameter should be replaced by the case-sensitive default name of the CSV import/export with "-" replacing any spaces. ' +
        'Example: Sales Order Details -> Sales-Order-Details',
    attributes: importAttributes,
    requestObject: importRequest,
    responseObject: importResponse,
}

export default data;
