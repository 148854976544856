import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const inventoryEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/parts/inventory',
        link: '#search-inventory',
        tooltip: 'Search for inventory information',
    },
    {
        type: 'POST',
        endpoint: '/parts/:id/inventory/add',
        link: '#add-inventory',
        tooltip: 'Add inventory',
    },
    {
        type: 'POST',
        endpoint: '/parts/:id/inventory/cycle',
        link: '#cycle-inventory',
        tooltip: 'Cycle inventory',
    },
    {
        type: 'POST',
        endpoint: '/parts/:id/inventory/move',
        link: '#move-inventory',
        tooltip: 'Move inventory',
    },
    {
        type: 'POST',
        endpoint: '/parts/:id/inventory/scrap',
        link: '#scrap-inventory',
        tooltip: 'Scrap inventory',
    },
];

export const inventoryHeader: HeaderData = {
    title: 'Inventory',
    description: 'This is an object representing the inventory associated with a Fishbowl part.',
    linkName: 'Inventory',
    link: 'https://www.fishbowlinventory.com/wiki/Inventory',
    endpoints: inventoryEndpoints,
};

export const jsonObject: Json = {
    title: 'THE INVENTORY OBJECT',
    json: {

    }
};

export const inventoryAttributes: Attribute[] = [];
