import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Link, Typography} from '@material-ui/core';

export default new RouteComponent(`${apiRoutePrefix}/connecting`, () => {

    return (
        <>
            <Box mb={ 3 }>
                <Typography variant="h4">Connecting</Typography>
            </Box>
            <Typography>
                To connect to the REST API you will need to know where your Fishbowl server is installed. This could be
                on your local network or through our hosting. The API port number is different than the client port and
                defaults to port 80. This can be configured in the server settings through Fishbowl Server
                Administration or Options on the Fishbowl Server application.
            </Typography>
            <Box mb={ 2 }/>
            <Typography>We recommend that you configure your sever to use a secure connection. This will require
                installing a certificate on the Fishbowl server.</Typography>
            <Box mb={ 2 }/>
            <Typography>The access rights that will be used during a session will be based on the user logged in through
                the integration.</Typography>
            <Box mb={ 2 }/>
            <Typography>To create a user session, use the <Link href="/apidocs/login">Login</Link> request. This will
                return a token that should be used on all future requests as the bearer token.</Typography>
            <Box mb={ 3 }/>
            <Typography>
                Related guide: <Link target="_blank" href="https://www.fishbowlinventory.com/wiki/Fishbowl_Server_Administration#Server_tab">Server
                Settings</Link>
            </Typography>
        </>
    );
});
