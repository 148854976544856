import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const searchPurchaseOrdersRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/purchase-orders',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/purchase-orders' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const searchPurchaseOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        'totalCount': 73,
        'totalPages': 37,
        'pageNumber': 1,
        'pageSize': 2,
        'results': [
            {
                'id': 10,
                'number': '10',
                'vendorName': 'A&B Distribution',
                'dateIssued': '2020-10-01T17:58:04.821-0600',
                'dateScheduled': '2020-08-02T08:54:21.000-0600',
                'status': 'Fulfilled'
            },
            {
                'id': 73,
                'number': '100',
                'vendorName': 'A&B Distribution',
                'dateIssued': '2020-11-23T11:42:58.984-0700',
                'dateScheduled': '2020-11-23T00:00:00.000-0700',
                'status': 'Void'
            }
        ]
    },
};

const searchPurchaseOrdersQueryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'poNumber',
        type: 'string',
        description: 'The purchase order number.'
    },
    {
        name: 'poType',
        type: 'string: \'Standard\' | \'Drop Ship\'',
        description: 'Indicates whether the order is a standard or drop ship purchase order.',
    },
    {
        name: 'vendorSoNumber',
        type: 'string',
        description: 'The vendor sales order number.'
    },
    {
        name: 'issuedFrom',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The start issued date cutoff for the search.'
    },
    {
        name: 'issuedTo',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The end issued date cutoff for the search.'
    },
    {
        name: 'fulfilledFrom',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The start fulfilled date cutoff for the search.'
    },
    {
        name: 'fulfilledTo',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The end fulfilled date cutoff for the search.'
    },
    {
        name: 'vendorName',
        type: 'string',
        description: 'The name of the vendor.',
    },
    {
        name: 'partNumber',
        type: 'string',
        description: 'The part number.',
    },
    {
        name: 'partDescription',
        type: 'string',
        description: 'The description on the part.',
    },
    {
        name: 'partDetails',
        type: 'string',
        description: 'The details for the part.',
    },
    {
        name: 'status',
        type: 'string: \'All\' | \'All Open\' | \'Bid Request\' | \'Pending Approval\' | \'Issued\' | \'Picking\' | \'Partial\' | \'Picked\' | \'Shipped\' | \'Fulfilled\' | \'Closed Short\' | \'Void\' | \'Historical\'',
        description: 'The order status.',
    },
    {
        name: 'customSoNumber',
        type: 'string',
        description: 'The customer sales order number.',
    },
    {
        name: 'locationGroupId',
        type: 'integer',
        description: 'The unique identifier for the location group on the order.',
    },
    {
        name: 'vendorPartNumber',
        type: 'string',
        description: 'The number for the vendor part.',
    },
    {
        name: 'buyerId',
        type: 'integer',
        description: 'The unique identifier for the salesperson on the order.',
    },
    {
        name: 'remitTo',
        type: 'string',
        description: 'The vendor address on the order.',
    },
    {
        name: 'shipTo',
        type: 'string',
        description: 'The ship to address on the order.',
    },
    {
        name: 'customerName',
        type: 'string',
        description: 'The name of the customer or job on the order.',
    },
];

const searchPurchaseOrdersAttributes: Attribute[] = [
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the order\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the custom field.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    }
];

const data: RequestDefinitionData = {
    callName: 'search-purchase-order',
    title: 'Search for purchase orders',
    description: 'Searches for purchase orders.',
    attributes: searchPurchaseOrdersAttributes,
    requestObject: searchPurchaseOrdersRequest,
    responseObject: searchPurchaseOrdersResponse,
    queryParameters: searchPurchaseOrdersQueryParameters,
};

export default data;
