import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const loginEndpoints: Endpoint[] = [
    {
        type: 'POST',
        endpoint: '/login',
        link: '#login',
        tooltip: 'Login',
    },
    {
        type: 'POST',
        endpoint: '/logout',
        link: '#logout',
        tooltip: 'Logout',
    },
];

export const loginHeader: HeaderData = {
    title: 'Login',
    description: 'The Login call allows you to log in to the Fishbowl Server. This should be the first call as it will return the session information that will ' +
        'be used in all subsequent calls. The logged in user will determine the access rights for calls made during the session. Logout to close the session. ',
    linkName: 'Starting Fishbowl',
    link: 'https://www.fishbowlinventory.com/wiki/Start_Fishbowl',
    endpoints: loginEndpoints,
};

export const jsonObject: Json = {
    title: 'THE LOGIN OBJECT',
    json: {
        'appName': 'App Name',
        'appDescription': 'my description',
        'appId': 12345,
        'username': 'JohnSmith',
        'password': 'password12345',
    },
};
