import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';
import {Attribute} from '../../components/ObjectAttribute';

const request: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/data-query',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/data-query' \\
--header 'Content-Type: application/sql' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw 'SELECT id, num FROM so WHERE statusId = 10'`
};

const response: Json = {
    title: 'RESPONSE',
    json: [{
        id: 30,
        num: 'S10040'
    }],
};

const attributes: Attribute[] = [

]

const data: RequestDefinitionData = {
    callName: 'data-query',
    title: 'Execute data query',
    description: 'Execute a data query against the database using SQL. Set content type of the request to application/sql',
    attributes: attributes,
    requestObject: request,
    responseObject: response,
}

export default data;
