import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const getMemoListRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/<OBJECT ENDPOINT>/:id/memos',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/<OBJECT ENDPOINT>/:id/memos' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const getMemoListResponse: Json = {
    title: 'RESPONSE',
    json: [
        {
            "id": 43,
            "memo": "This is my other memo",
            "username": "dave",
            "dateCreated": "2021-02-26T14:22:16.383-0700"
        },
        {
            "id": 42,
            "memo": "This is a memo",
            "username": "dave",
            "dateCreated": "2021-02-26T13:59:53.171-0700"
        }
    ]
};

const data: RequestDefinitionData = {
    callName: 'get-memo-list',
    title: 'Get a list of memos',
    description: 'Retrieves a list of memos for the specific object.',
    attributes: [],
    requestObject: getMemoListRequest,
    responseObject: getMemoListResponse,
};

export default data;
