import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import {
    jsonObject,
    manufactureOrderAttributes,
    manufactureOrdersHeader
} from '../../data/manufactureOrders/manufactureOrder';
import getManufactureOrderRequest from '../../data/manufactureOrders/getManufactureOrderRequest';
import createManufactureOrderRequest from '../../data/manufactureOrders/createManufactureOrderRequest';
import issueManufactureOrderRequest from '../../data/manufactureOrders/issueManufactureOrderRequest';
import unissueManufactureOrderRequest from '../../data/manufactureOrders/unissueManufactureOrderRequest';
import closeShortManufactureOrdersRequest from '../../data/manufactureOrders/closeShortManufactureOrderRequest';
import deleteManufactureOrderRequest from '../../data/manufactureOrders/deleteManufactureOrderRequest';
import searchManufactureOrdersRequest from '../../data/manufactureOrders/searchManufactureOrdersRequest';
import updateManufactureOrderRequest from '../../data/manufactureOrders/updateManufactureOrderRequest';
import RequestDefinition from '../RequestDefinition';
import ObjectDefinition from '../ObjectDefinition';

export default new RouteComponent(`${apiRoutePrefix}/manufacture-orders`, () => {
    const requests = [
        searchManufactureOrdersRequest,
        getManufactureOrderRequest,
        createManufactureOrderRequest,
        updateManufactureOrderRequest,
        issueManufactureOrderRequest,
        unissueManufactureOrderRequest,
        closeShortManufactureOrdersRequest,
        deleteManufactureOrderRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ manufactureOrdersHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The manufacture order object',
                attributes: manufactureOrderAttributes,
                jsonObject: jsonObject
            } }/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
