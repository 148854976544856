import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {inventoryHeader} from '../../data/inventory/inventory';
import searchInventoryRequest from '../../data/inventory/searchInventoryRequest';
import addInventoryRequest from '../../data/inventory/addInventoryRequest';
import cycleInventoryRequest from "../../data/inventory/cycleInventoryRequest";
import moveInventoryRequest from '../../data/inventory/moveInventoryRequest';
import scrapInventoryRequest from '../../data/inventory/scrapInventoryRequest';

export default new RouteComponent(`${apiRoutePrefix}/parts/inventory`, () => {
    const requests = [
        searchInventoryRequest,
        addInventoryRequest,
        cycleInventoryRequest,
        moveInventoryRequest,
        scrapInventoryRequest
    ];

    return (
        <div>
            <ObjectHeader data={ inventoryHeader }/>

            {/*<Box mb={ 3 }>*/}
            {/*    <Divider/>*/}
            {/*</Box>*/}

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
