import React from 'react';
import {Box, Container, Link, Typography} from '@material-ui/core';
import {RouteComponent} from '../RouteComponent';
import DocumentationScreen from '../documentation/DocumentationScreen';

export default new RouteComponent('/', () => {

    return (
        <Container>
            <Box mt={ 10 }>
                <Typography align="center">
                    <Typography variant="h2">You've found us!</Typography>
                    <img src="fblogo.png" alt="Fishbowl Logo" style={ { paddingBottom: '1%', paddingTop: '1%' } }/>
                    <Typography variant="h5">This is the future home of something really cool... that could be under
                        construction for a while.</Typography>
                </Typography>
            </Box>
            <Box mt={ 50 }>
                <Link href={ DocumentationScreen.path } target="_blank">REST Documentation</Link>
            </Box>
        </Container>
    );
});
