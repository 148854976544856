import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const searchUsersRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/users',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/users' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const searchUsersResponse: Json = {
    title: 'RESPONSE',
    json: {
        'totalCount': 1,
        'totalPages': 1,
        'pageNumber': 1,
        'pageSize': 1,
        'results': [
            {
                "id": 1,
                "username": "admin",
                "firstName": "Administrator",
                "lastName": "Administrator",
                "initials": "ADM",
                "active": true
            },
        ]
    },
};

const searchUsersQueryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'username',
        type: 'string',
        description: 'The username.',
    },
    {
        name: 'firstName',
        type: 'string',
        description: 'The first name of the user.',
    },
    {
        name: 'lastName',
        type: 'string',
        description: 'The last name of the user.',
    },
    {
        name: 'initials',
        type: 'string',
        description: 'The initials of the user\'s name.',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'The active status of the user.',
    },
];

const searchUsersAttributes: Attribute[] = [
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the custom fields associated with the user.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the custom field.',
            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    },
];

const data: RequestDefinitionData = {
    callName: 'search-user',
    title: 'Search for users',
    description: 'Searches for users.',
    attributes: searchUsersAttributes,
    requestObject: searchUsersRequest,
    responseObject: searchUsersResponse,
    queryParameters: searchUsersQueryParameters,
}

export default data;
