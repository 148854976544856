import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const getProductBestPriceRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/products/:id/best-price',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/products/:id/best-price' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const getProductBestPriceResponse: Json = {
    title: 'RESPONSE',
    json: {
        "bestUnitPrice": "23.75",
        "pricingRules": [
            {
                "id": 65,
                "name": "Bike Club 4",
                "description": "Must present Club Card",
                "unitPrice": "22.5",
                "valid": false
            },
            {
                "id": 67,
                "name": "Bike Club 5",
                "description": "Must present Club Card",
                "unitPrice": "23.75",
                "valid": true
            }
        ]
    }
};

const getProductBestPriceQueryParameters: Attribute[] = [
    {
        name: 'customerId',
        type: 'integer',
        description: 'The unique identifier for the customer associated with the product.'
    },
    {
        name: 'quantity',
        type: 'quantity',
        description: 'The product quantity associated with the cost.'
    },
    {
        name: 'date',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The date on which the best price will be calculated.'
    },
    {
        name: 'uomId',
        type: 'integer',
        description: 'The unique identifier for the unit of measure.'
    },
    {
        name: 'includePricingRules',
        type: 'boolean',
        description: 'Indicates whether the list of pricing rules should be returned.'
    }

];
const data: RequestDefinitionData = {
    callName: 'get-product-best-price',
    title: 'Get the best price for the product',
    description: 'Retrieves the best unit price for the specific product.',
    attributes: [],
    requestObject: getProductBestPriceRequest,
    responseObject: getProductBestPriceResponse,
    queryParameters: getProductBestPriceQueryParameters,
    wikiLinkName: 'Pricing Rule',
    wikiLink: 'https://help.fishbowlinventory.com/hc/en-us/articles/360043110073-Pricing-Rule',
};

export default data;
