import React from 'react';
import {Box, createStyles, Divider, Grid, Link, Theme, Typography} from '@material-ui/core';
import AttributeList from './AttributeList';
import JsonObjectCard, {Json} from './JsonObjectCard';
import {Attribute} from './ObjectAttribute';
import {makeStyles} from '@material-ui/core/styles';
import RequestExampleCard, {RequestExample} from './RequestExampleCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sticky: {
            position: 'sticky',
            top: '6rem',
        },
        mdHidden: {
            [theme.breakpoints.up('md')]: {
                visibility: 'hidden',
            }
        },
        offset: {
            display: 'block',
            position: 'relative',
            top: `-${ +theme.mixins.toolbar.minHeight! + 20 }px`,
            visibility: 'hidden'
        },
        guideSpacing: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            }
        },
        title: {
            fontWeight: 'bold',
        }
    })
);

export interface RequestDefinitionData {
    callName: string,
    wikiLink?: string,
    wikiLinkName?: string,
    title: string,
    description: string,
    queryParameters?: Attribute[],
    attributes: Attribute[],
    requestObject: RequestExample,
    responseObject: Json,
}

const RequestDefinition: React.FC<RequestDefinitionData> = (properties) => {
    const classes = useStyles();
    const { callName, wikiLink, wikiLinkName, title, description, queryParameters, attributes, requestObject, responseObject } = properties;
    return (
        <>
            <Box mb={ 3 }>
                <div id={ callName } className={ classes.offset }/>
                <Typography variant="h5" className={ classes.title }>{ title }</Typography>
                <Box mb={ 1 }/>
                <Typography>{ description }</Typography>
                { wikiLink &&
                <Box my={ 3 } className={ classes.guideSpacing }>
                    <Typography>
                        Related guide: <Link target="_blank" href={ wikiLink }>{ wikiLinkName }</Link>
                    </Typography>
                </Box>
                }
            </Box>
            <Grid container spacing={ 3 }>
                <Grid item md={ 6 } xs={ 12 }>
                    { queryParameters && <AttributeList title="Parameters" attributes={ queryParameters }/> }
                    <AttributeList attributes={ attributes }/>

                    <Divider className={ classes.mdHidden }/>
                </Grid>
                <Grid item md={ 6 } xs={ 12 }>
                    <Box className={ classes.sticky } mb={ 3 }>
                        <RequestExampleCard requestExample={ requestObject }/>
                        <Box my={ 3 }/>
                        <JsonObjectCard jsonData={ responseObject }/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default RequestDefinition;
