import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const usersEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/users',
        link: '#search-user',
        tooltip: 'Search for users',
    },
];

export const usersHeader: HeaderData = {
    title: 'Users',
    description: 'This is an object representing a Fishbowl user.',
    linkName: 'Users',
    link: 'https://www.fishbowlinventory.com/wiki/User',
    endpoints: usersEndpoints,
};

export const jsonObject: Json = {
    title: 'THE USER OBJECT',
    json: {
        "id": 1,
        "username": "admin",
        "firstName": "Administrator",
        "lastName": "Administrator",
        "initials": "ADM",
        "active": true
    },
};

export const userAttributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The user\'s unique identification number.'
    },
    {
        name: 'username',
        type: 'string',
        description: 'The username.',
    },
    {
        name: 'firstName',
        type: 'string',
        description: 'The first name of the user.',
    },
    {
        name: 'lastName',
        type: 'string',
        description: 'The last name of the user.',
    },
    {
        name: 'initials',
        type: 'string',
        description: 'The initials of the user\'s name.',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'The active status of the user.',
    },
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the custom fields associated with the user.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The custom field\'s unique identifier.',

            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the custom field.',
            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
            {
                name: 'type',
                type: 'string',
                description: 'The category of the custom field\'s value.'
            },
        ]
    },
];
