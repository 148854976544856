import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const uomsEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/uoms',
        link: '#search-uom',
        tooltip: 'Search for UOMs',
    },
    {
        type: 'GET',
        endpoint: '/uoms/:id',
        link: '#get-uom',
        tooltip: 'Get a unit of measure',
    },
    {
        type: 'POST',
        endpoint: '/uoms',
        link: '#create-uom',
        tooltip: 'Create a unit of measure',
    },
    {
        type: 'POST',
        endpoint: '/uoms/:id',
        link: '#update-uom',
        tooltip: 'Update a unit of measure',
    },
    {
        type: 'DELETE',
        endpoint: '/uoms/:id',
        link: '#delete-uom',
        tooltip: 'Delete a unit of measure',
    },
];

export const uomsHeader: HeaderData = {
    title: 'Unit of Measure',
    description: 'This is an object representing a Fishbowl unit of measure (UOM).',
    linkName: 'Unit of Measure',
    link: 'https://www.fishbowlinventory.com/wiki/Unit_of_Measure',
    endpoints: uomsEndpoints,
};

export const jsonObject: Json = {
    title: 'THE UOM OBJECT',
    json: {
        "id": 1,
        "name": "Each",
        "abbreviation": "ea",
        "description": "A single item.",
        "type": "Count",
        "active": true,
        "integral": true,
        "readOnly": true,
        "conversions": [
            {
                "id": 32,
                "description": "1 Pair = 2.0 Each",
                "targetUom": {
                    "id": 18,
                    "name": "Pair",
                    "abbreviation": "pr"
                },
                "divisor": "2",
                "multiplier": "1"
            }
        ]
    },
};

export const uomAttributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The UOM\'s unique identification number.'
    },{
        name: 'name',
        type: 'string',
        description: 'The UOM name.',
    },
    {
        name: 'abbreviation',
        type: 'string',
        description: 'The UOM abbreviation.',
    },
    {
        name: 'description',
        type: 'string',
        description: 'The UOM description.',
    },
    {
        name: 'type',
        type: 'string: \'Area\' | \'Count\' | \'Length\' | \'Time\' | \'Volume\' | \'Weight\'',
        description: 'The basic type of the UOM.',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'The active status of the UOM.',
    },
    {
        name: 'integral',
        type: 'boolean',
        description: 'Indicates if the quantity must be a whole number.',
    },
    {
        name: 'readOnly',
        type: 'boolean',
        description: 'Indicates if the UOM is read-only.',
    },
    {
        name: 'conversions',
        type: 'list',
        description: 'A list of the UOM conversions.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The conversion\'s unique identification number.',
            },
            {
                name: 'description',
                type: 'string',
                description: 'The conversion description.',
            },
            {
                name: 'targetUom',
                type: 'reference object',
                description: 'The resulting UOM of the conversion.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                    {
                        name: 'abbreviation',
                        type: 'string',
                        description: 'The abbreviated name.',
                    },
                ]
            },
            {
                name: 'divisor',
                type: 'quantity',
                description: 'Divide the quantity by the divisor to convert to the target UOM.',
            },
            {
                name: 'multiplier',
                type: 'quantity',
                description: 'Multiply the quantity by the multiplier to convert to the target UOM.',
            },
        ]
    }
];
