import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {partHeader} from '../../data/part/part';
import getPartBestCostRequest from '../../data/part/getPartBestCost';
import searchPartsRequest from '../../data/part/searchPartsRequest';

export default new RouteComponent(`${apiRoutePrefix}/parts`, () => {

    return (
        <div>
            <ObjectHeader data={ partHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <RequestDefinition {...searchPartsRequest}/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <RequestDefinition {...getPartBestCostRequest}/>
        </div>
    );
});
