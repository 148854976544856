import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const updatePluginInfoRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/integrations/plugin-info/:id',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/integrations/plugin-info/:id' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<PLUGIN INFO JSON>'
`,
};
const updatePluginInfoResponse: Json = {
    title: 'RESPONSE',
    json: [
        {
            "id": 31,
            "plugin": "Fishbowl Time",
            "table": "WO",
            "recordId": 72,
            "groupId": 10,
            "channelId": "b674f2bf-7ea2-491f-8d70-8cf4fd3243fc"
        },
        {
            "id": 32,
            "plugin": "Fishbowl Time",
            "table": "WO",
            "recordId": 72,
            "groupId": 20,
            "channelId": "27d1891e-0627-4abf-87a6-9a38db5d78f2",
            "info": {
                "id": 123
            }
        }
    ]
};

const updatePluginInfoParameters: Attribute[] = [
    {
        name: 'plugin',
        type: 'string',
        description: 'The name of the plugin.',
    },
    {
        name: 'authorization',
        type: 'string',
        description: 'The user specified code for authorization.',
    },
];

const updatePluginInfoAttributes: Attribute[] = [
    {
        name: 'info',
        type: 'json',
        description: 'The information to be updated.',
    },
];

const data: RequestDefinitionData = {
    callName: 'update-plugin-info',
    title: 'Update a plugin information',
    description: 'Updates a plugin information.',
    queryParameters: updatePluginInfoParameters,
    attributes: updatePluginInfoAttributes,
    requestObject: updatePluginInfoRequest,
    responseObject: updatePluginInfoResponse,
}

export default data;
