import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const getPurchaseOrderRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/purchase-orders/:id',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/purchase-orders/:id' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const getPurchaseOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        'id': 72,
        'number': '50071',
        'carrier': {
            'id': 5,
            'name': 'USPS',
        },
        'fobPointName': 'Origin',
        'paymentTerms': {
            'id': 4,
            'name': 'Net 30'
        },
        'vendor': {
            'id': 17,
            'name': 'Brent\'s Bicycle Brakes'
        },
        'vendorSoNumber': 'S108',
        'customerSoNumber': '10086',
        'vendorContact': 'Brent\'s Bicycle Brakes',
        'buyer': {
            'id': 1,
            'name': 'admin'
        },
        'deliverTo': 'Allan\'s Raceway',
        'revisionNumber': 3,
        'lastModified': {
            'username': 'admin',
            'dateLastModified': '2021-02-24T13:49:20.493-0700'
        },
        'issuedByUser': {
            'username': 'admin',
            'dateLastModified': '2021-02-24T13:49:20.490-0700'
        },
        'dateCreated': '2020-11-18T00:00:00.000-0700',
        'dateRevision': '2020-11-18T15:44:40.839-0700',
        'dateScheduled': '2020-11-18T00:00:00.000-0700',
        'totalTax': '0',
        'totalIncludesTax': false,
        'locationGroup': {
            'id': 5,
            'name': 'SLC'
        },
        'note': 'Created for SO #10086',
        'url': '',
        'currency': {
            'id': 1,
            'name': 'US Dollar',
            'code': 'USD',
            'rate': '1',
            'homeCurrency': true
        },
        'email': 'brent@example.com',
        'phone': '801-932-1101',
        'shipToAddress': {
            'name': 'Allan\'s Raceway',
            'street': '2222 Wrecker\'s Blvd',
            'city': 'Santa Barbara',
            'state': 'CA',
            'postalCode': '93101',
            'country': 'UNITED STATES'
        },
        'remitToAddress': {
            'name': 'Brent\'s Bicycle Brakes',
            'street': '777 South Delivery Lane',
            'city': 'Salt Lake City',
            'state': 'UT',
            'postalCode': '84111',
            'country': 'UNITED STATES'
        },
        'status': 'Issued',
        'class': {
            'id': 12,
            'name': 'LA:LA Store'
        },
        'shipTerms': 'Prepaid & Billed',
        'type': 'Drop Ship',
        'poItems': [
            {
                'id': 182,
                'part': {
                    'id': 28,
                    'name': 'B201',
                    'description': 'Premium Brake Cables'
                },
                'type': {
                    'id': 10,
                    'name': 'Purchase'
                },
                'lineNumber': 1,
                'vendorPartNumber': 'B1-7878',
                'quantity': '1',
                'quantityFulfilled': '0',
                'quantityPicked': '0',
                'unitCost': '9',
                'totalCost': '9',
                'mcTotalCost': '9',
                'taxRate': {
                    'id': 1,
                    'name': 'None',
                    'rate': 0.0
                },
                'dateScheduledFulfillment': '2020-11-18T15:44:34.096-0700',
                'revision': '',
                'note': '',
                'customer': {
                    'id': 52,
                    'name': 'Allan\'s Raceway'
                },
                'uomId': {
                    'id': 1,
                    'name': 'Each',
                    'abbreviation': 'ea'
                },
                'status': 'Entered',
                'class': {
                    'id': 12,
                    'name': 'LA:LA Store'
                },
                'customFields': []
            }
        ],
        'customFields': [
            {
                'id': 19,
                'name': 'Custom',
                'value': 'My Custom Field',
                'type': 'Text'
            }
        ]
    },
};

const data: RequestDefinitionData = {
    callName: 'get-purchase-order',
    title: 'Get a purchase order',
    description: 'Retrieves the details of an existing purchase order. You only need to provide the unique purchase order ID.',
    attributes: [],
    requestObject: getPurchaseOrderRequest,
    responseObject: getPurchaseOrdersResponse,
};

export default data;
