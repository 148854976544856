import React from 'react';
import {AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {apiRoutePrefix} from '../../../RouteComponent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        title: {
            paddingLeft: '.8rem'
        }
    })
);

const FbAppBar: React.FC = () => {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={ classes.appBar }>
            <Toolbar>
                <IconButton href={ apiRoutePrefix }>
                    <img src="/fblogo32.png" alt="Fishbowl"/>
                </IconButton>
                <Typography variant="h6" noWrap className={ classes.title }>
                    Fishbowl API Documentation - Version {process.env.REACT_APP_FB_VERSION}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default FbAppBar;
