import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import ObjectDefinition from '../ObjectDefinition';
import RequestDefinition from '../RequestDefinition';
import {jsonObject, uomAttributes, uomsHeader} from '../../data/uom/uoms';
import searchUomsRequest from '../../data/uom/searchUomsRequest';
import getUomRequest from '../../data/uom/getUomRequest';
import deleteUomRequest from '../../data/uom/deleteUomRequest';
import createUomRequest from '../../data/uom/createUomRequest';
import updateUomRequest from '../../data/uom/updateUomRequest';

export default new RouteComponent(`${apiRoutePrefix}/uoms`, () => {
    const requests = [
        searchUomsRequest,
        getUomRequest,
        createUomRequest,
        updateUomRequest,
        deleteUomRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ uomsHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The UOM object',
                attributes: uomAttributes,
                jsonObject: jsonObject
            } }/>
            {
                requests.map(request => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...request}/>
                    </>
                ))
            }
        </div>
    );
});
