import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const deleteManufactureOrderRequest: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/manufacture-orders/:id',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/manufacture-orders/:id' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const deleteManufactureOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {},
};

const data: RequestDefinitionData = {
    callName: 'delete-manufacture-order',
    title: 'Delete a manufacture order',
    description: 'Deletes the manufacture order with the specified ID.',
    attributes: [],
    requestObject: deleteManufactureOrderRequest,
    responseObject: deleteManufactureOrdersResponse,
};

export default data;
