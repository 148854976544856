import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const vendorEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/vendors',
        link: '#search-vendor',
        tooltip: 'Search for vendors',
    },
];

export const vendorHeader: HeaderData = {
    title: 'Vendor',
    description: 'This is an object representing a Fishbowl vendor.',
    linkName: 'Vendor',
    link: 'https://www.fishbowlinventory.com/wiki/Vendor',
    endpoints: vendorEndpoints,
};

export const jsonObject: Json = {
    title: 'THE VENDOR OBJECT',
    json: {}
};

export const vendorAttributes: Attribute[] = [];
