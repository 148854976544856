import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

export const closeShortManufactureOrderRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/manufacture-orders/:id/close-short',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/manufacture-orders/:id/close-short' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

export const closeShortManufactureOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 29,
        "number": "1020",
        "revisionNumber": 2,
        "note": "",
        "locationGroup": {
            "id": 5,
            "name": "SLC"
        },
        "dateCreated": "2021-05-21T16:20:22.469-0600",
        "lastModified": {
            "username": "admin",
            "dateLastModified": "2021-05-21T16:21:38.732-0600"
        },
        "dateIssued": "2021-05-21T16:20:22.469-0600",
        "dateScheduled": "2021-05-21T16:19:32.715-0600",
        "url": "",
        "percentComplete": "100%",
        "status": "Closed Short",
        "class": {
            "id": 1,
            "name": "None"
        },
        "configurations": [
            {
                "id": 323,
                "bom": {
                    "id": 6,
                    "name": "LI100"
                },
                "priceAdjustment": "0",
                "unitCost": "0",
                "dateScheduled": "2021-05-21T16:19:00.000-0600",
                "dateScheduledToStart": "2021-05-21T16:19:00.000-0600",
                "note": "",
                "sortId": 1,
                "stageLevel": 1,
                "hasSo": false,
                "status": "Closed Short",
                "quantity": "2",
                "quantityFulfilled": "0",
                "class": {
                    "id": 1,
                    "name": "None"
                },
                "priority": "3-Normal",
                "items": [
                    {
                        "id": 324,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 1,
                        "part": {
                            "id": 46,
                            "name": "LI100",
                            "description": "Bikers lighting system for cycling safety"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Partial",
                        "quantity": "2",
                        "quantityFulfilled": "1",
                        "type": "Finished Good"
                    },
                    {
                        "id": 325,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 2,
                        "part": {
                            "id": 47,
                            "name": "LED001",
                            "description": "Small LED handlebar mounted light"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Closed Short",
                        "quantity": "2",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    },
                    {
                        "id": 326,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 3,
                        "part": {
                            "id": 76,
                            "name": "LED002",
                            "description": "Small LED tail light"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Partial",
                        "quantity": "2",
                        "quantityFulfilled": "1",
                        "type": "Raw Good"
                    },
                    {
                        "id": 327,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 4,
                        "part": {
                            "id": 34,
                            "name": "BTY100",
                            "description": "Battery Pack"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Closed Short",
                        "quantity": "2",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    }
                ]
            }
        ],
        "customFields": [
            {
                "id": 25,
                "name": "Custom",
                "value": "",
                "type": "Text"
            },
            {
                "id": 29,
                "name": "Type",
                "value": "",
                "type": "Drop-Down List"
            }
        ]
    },
};

const data: RequestDefinitionData = {
    callName: 'close-short-manufacture-order',
    title: 'Close short a manufacture order',
    description: 'Closes short the manufacture order with the specified ID.',
    attributes: [],
    requestObject: closeShortManufactureOrderRequest,
    responseObject: closeShortManufactureOrdersResponse,
};

export default data;
