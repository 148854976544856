import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const deleteMemoRequest: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/<OBJECT ENDPOINT>/:id/memos/:memoId',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/<OBJECT ENDPOINT>/:id/memos/:memoId' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const deleteMemoResponse: Json = {
    title: 'RESPONSE',
    json: {},
};
const data: RequestDefinitionData = {
    callName: 'delete-memo',
    title: 'Delete a memo',
    description: 'Deletes the memo with the specified ID.',
    attributes: [],
    requestObject: deleteMemoRequest,
    responseObject: deleteMemoResponse,
};

export default data;
