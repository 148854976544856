import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const createPurchaseOrderRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/purchase-orders',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/purchase-orders' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<PO JSON>'
`,
};

const createPurchaseOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        'id': 72,
        'number': '50071',
        'carrier': {
            'id': 5,
            'name': 'USPS',
            'service': {
                'id': 16,
                'name': 'Priority Mail'
            }
        },
        'fobPointName': 'Origin',
        'paymentTerms': {
            'id': 4,
            'name': 'Net 30'
        },
        'vendor': {
            'id': 17,
            'name': 'Brent\'s Bicycle Brakes'
        },
        'vendorSoNumber': 'S108',
        'customerSoNumber': '10086',
        'vendorContact': 'Brent\'s Bicycle Brakes',
        'buyer': {
            'id': 1,
            'name': 'admin'
        },
        'deliverTo': 'Allan\'s Raceway',
        'revisionNumber': 3,
        'lastModified': {
            'username': 'admin',
            'dateLastModified': '2021-02-24T13:49:20.493-0700'
        },
        'issuedByUser': {
            'username': 'admin',
            'dateLastModified': '2021-02-24T13:49:20.490-0700'
        },
        'dateCreated': '2020-11-18T00:00:00.000-0700',
        'dateRevision': '2020-11-18T15:44:40.839-0700',
        'dateScheduled': '2020-11-18T00:00:00.000-0700',
        'totalTax': '0',
        'totalIncludesTax': false,
        'locationGroup': {
            'id': 5,
            'name': 'SLC'
        },
        'note': 'Created for SO #10086',
        'url': '',
        'currency': {
            'id': 1,
            'name': 'US Dollar',
            'code': 'USD',
            'rate': '1',
            'homeCurrency': true
        },
        'email': 'brent@example.com',
        'phone': '801-932-1101',
        'shipToAddress': {
            'name': 'Allan\'s Raceway',
            'street': '2222 Wrecker\'s Blvd',
            'city': 'Santa Barbara',
            'state': 'CA',
            'postalCode': '93101',
            'country': 'UNITED STATES'
        },
        'remitToAddress': {
            'name': 'Brent\'s Bicycle Brakes',
            'street': '777 South Delivery Lane',
            'city': 'Salt Lake City',
            'state': 'UT',
            'postalCode': '84111',
            'country': 'UNITED STATES'
        },
        'status': 'Issued',
        'class': {
            'id': 12,
            'name': 'LA:LA Store'
        },
        'shipTerms': 'Prepaid & Billed',
        'type': 'Drop Ship',
        'poItems': [
            {
                'id': 182,
                'part': {
                    'id': 28,
                    'name': 'B201',
                    'description': 'Premium Brake Cables'
                },
                'type': {
                    'id': 10,
                    'name': 'Purchase'
                },
                'lineNumber': 1,
                'vendorPartNumber': 'B1-7878',
                'quantity': '1',
                'quantityFulfilled': '0',
                'quantityPicked': '0',
                'unitCost': '9',
                'totalCost': '9',
                'mcTotalCost': '9',
                'taxRate': {
                    'id': 1,
                    'name': 'None',
                    'rate': 0.0
                },
                'dateScheduledFulfillment': '2020-11-18T15:44:34.096-0700',
                'revision': '',
                'note': '',
                'customer': {
                    'id': 52,
                    'name': 'Allan\'s Raceway'
                },
                'uomId': {
                    'id': 1,
                    'name': 'Each',
                    'abbreviation': 'ea'
                },
                'status': 'Entered',
                'class': {
                    'id': 12,
                    'name': 'LA:LA Store'
                },
                'customFields': []
            }
        ],
        'customFields': [
            {
                'id': 19,
                'name': 'Custom',
                'value': 'My Custom Field',
                'type': 'Text'
            }
        ]
    },
};

const createPurchaseOrdersAttributes: Attribute[] = [
    {
        name: 'number',
        type: 'string',
        optional: true,
        description: 'The purchase order number.'
    },
    {
        name: 'status',
        type: 'string',
        optional: true,
        description: 'The order status.'
    },
    {
        name: 'class',
        type: 'reference object',
        optional: true,
        description: 'The class category.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'carrier',
        type: 'reference object',
        optional: true,
        description: 'The shipping carrier.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'service',
                type: 'reference object',
                optional: true,
                description: 'The carrier service.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            }
        ]
    },
    {
        name: 'fobPointName',
        type: 'string',
        optional: true,
        description: 'Indicates when ownership/liability of the order transfers to the purchaser.'
    },
    {
        name: 'paymentTerms',
        type: 'reference object',
        optional: true,
        description: 'The terms of payment on the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'shipTerms',
        type: 'string',
        optional: true,
        description: 'The shipping terms on the order.',
    },
    {
        name: 'vendor',
        type: 'reference object',
        description: 'The vendor on the purchase order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'vendorSoNumber',
        type: 'string',
        optional: true,
        description: 'The vendor sales order number.',
    },
    {
        name: 'customerSoNumber',
        type: 'string',
        optional: true,
        description: 'The customer sales order number.',
    },
    {
        name: 'buyer',
        type: 'reference object',
        optional: true,
        description: 'The Fishbowl user that created the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'deliverTo',
        type: 'string',
        optional: true,
        description: 'The intended recipient of the order.',
    },
    {
        name: 'revisionNumber',
        type: 'integer',
        optional: true,
        description: 'The revision number.',
    },
    {
        name: 'dateCreated',
        type: 'date',
        optional: true,
        description: 'Timestamp of when the order was created.',
    },
    {
        name: 'dateConfirmed',
        type: 'date',
        optional: true,
        description: 'Timestamp of when the order was confirmed by the vendor.',
    },
    {
        name: 'dateScheduled',
        type: 'date',
        optional: true,
        description: 'Timestamp of when the order is scheduled to be fulfilled.',
    },
    {
        name: 'taxRate',
        type: 'reference object',
        optional: true,
        description: 'The tax rate on the order. For international orders.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'totalIncludesTax',
        type: 'boolean',
        optional: true,
        description: 'Indicates if the order total includes tax. For international orders.',
    },
    {
        name: 'locationGroup',
        type: 'reference object',
        optional: true,
        description: 'The location group the order belongs to.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'note',
        type: 'string',
        optional: true,
        description: 'The order\'s note field.',
    },
    {
        name: 'url',
        type: 'string',
        optional: true,
        description: 'The url link on the order.',
    },
    {
        name: 'currency',
        type: 'reference object',
        optional: true,
        description: 'The currency used on the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'rate',
                type: 'number',
                description: 'The currency exchange rate.',
            }
        ]
    },
    {
        name: 'email',
        type: 'string',
        optional: true,
        description: 'The vendor\'s email address for the order.',
    },
    {
        name: 'phone',
        type: 'string',
        optional: true,
        description: 'The vendor\'s phone number for the order.',
    },
    {
        name: 'shipToAddress',
        type: 'object',
        optional: true,
        description: 'The shipping address on the order.',
        children: [
            {
                name: 'name',
                type: 'string',
                optional: true,
                description: 'The name on the address.',
            },
            {
                name: 'street',
                type: 'string',
                description: 'The street line.',
            },
            {
                name: 'city',
                type: 'string',
                optional: true,
                description: 'The city.',
            },
            {
                name: 'state',
                type: 'string',
                optional: true,
                description: 'The state\'s abbreviation.',
            },
            {
                name: 'postalCode',
                type: 'string',
                optional: true,
                description: 'The postal code.',
            },
            {
                name: 'country',
                type: 'string',
                optional: true,
                description: 'The country\'s abbreviation.',
            }
        ]
    },
    {
        name: 'remitToAddress',
        type: 'object',
        optional: true,
        description: 'The remit to address on the order.',
        children: [
            {
                name: 'name',
                type: 'string',
                optional: true,
                description: 'The name on the address.',
            },
            {
                name: 'street',
                type: 'string',
                description: 'The street line.',
            },
            {
                name: 'city',
                type: 'string',
                optional: true,
                description: 'The city.',
            },
            {
                name: 'state',
                type: 'string',
                optional: true,
                description: 'The state\'s abbreviation.',
            },
            {
                name: 'postalCode',
                type: 'string',
                optional: true,
                description: 'The postal code.',
            },
            {
                name: 'country',
                type: 'string',
                optional: true,
                description: 'The country\'s abbreviation.',
            }
        ]
    },
    {
        name: 'poItems',
        type: 'list',
        optional: true,
        description: 'A list of the purchase order items.',
        children: [
            {
                name: 'part',
                type: 'reference object',
                description: 'The part on the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        optional: true,
                        description: 'The unique identification number. (Optional when miscellaneous type)',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        optional: true,
                        description: 'The part number. (Required when miscellaneous type)',
                    },
                    {
                        name: 'description',
                        type: 'string',
                        optional: true,
                        description: 'The description of the object.',
                    },
                ]
            },
            {
                name: 'outsourcedPart',
                type: 'reference object',
                optional: true,
                description: 'The outsourced part on the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'description',
                        type: 'string',
                        optional: true,
                        description: 'The description of the object.',
                    },
                ]
            },
            {
                name: 'type',
                type: 'reference object',
                optional: true,
                description: 'The order item\'s type.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'uom',
                type: 'reference object',
                optional: true,
                description: 'The part unit of measure.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'lineNumber',
                type: 'integer',
                optional: true,
                description: 'The line number for the order item.',
            },
            {
                name: 'vendorPartNumber',
                type: 'string',
                optional: true,
                description: 'The vendor\'s number for the part.',
            },
            {
                name: 'quantity',
                type: 'quantity',
                description: 'The quantity to be fulfilled.',
            },
            {
                name: 'totalCost',
                type: 'money',
                description: 'The total cost of the part.',
            },
            {
                name: 'mcTotalCost',
                type: 'money',
                optional: true,
                description: 'The multi-currency total cost of the part.',
            },
            {
                name: 'taxRate',
                type: 'reference object',
                optional: true,
                description: 'The tax rate for the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'dateScheduled',
                type: 'date',
                optional: true,
                description: 'The scheduled fulfillment date.',
            },
            {
                name: 'revision',
                type: 'string',
                optional: true,
                description: 'The revision number for the order item.',
            },
            {
                name: 'class',
                type: 'reference object',
                optional: true,
                description: 'The class category for the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'note',
                type: 'string',
                optional: true,
                description: 'The note on the order item.',
            },
            {
                name: 'customer',
                type: 'reference object',
                optional: true,
                description: 'The customer on the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'customFields',
                type: 'list',
                optional: true,
                description: 'A list of the order\'s custom fields.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The custom field\'s unique identifier.',

                    },
                    {
                        name: 'value',
                        type: 'string',
                        description: 'The custom field\'s value.'
                    },
                ]
            },
        ]
    },
    {
        name: 'customFields',
        type: 'list',
        optional: true,
        description: 'A list of the order\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The custom field\'s unique identifier.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    },
];

const data: RequestDefinitionData = {
    callName: 'create-purchase-order',
    title: 'Create a purchase order',
    description: 'Creates a purchase order.',
    attributes: createPurchaseOrdersAttributes,
    requestObject: createPurchaseOrderRequest,
    responseObject: createPurchaseOrdersResponse,
};

export default data;
