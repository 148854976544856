import React from 'react';
import {createStyles, List, makeStyles, Theme, Typography} from '@material-ui/core';
import ObjectAttribute, {Attribute} from './ObjectAttribute';

interface Props {
    title?: string,
    attributes: Attribute[],
}

const AttributeList: React.FC<Props> = ({ title = 'Attributes', attributes }) => {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            noAttributes: {
                color: theme.palette.text.secondary,
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Typography variant="h6">{ title }</Typography>

            <List>
                { attributes.length > 0
                    ? attributes.map(attribute => <ObjectAttribute key={ attribute.name } attribute={ attribute }/>)
                    : <Typography className={ classes.noAttributes }>No attributes.</Typography>
                }
            </List>
        </>
    );
};

export default AttributeList;
