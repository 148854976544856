import {Theme, ThemeOptions} from '@material-ui/core/styles/createMuiTheme';
import {createMuiTheme} from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        // example
        //     endpointVerb: {
        //         get: React.CSSProperties['color'],
        //         post: React.CSSProperties['color'],
        //         delete: React.CSSProperties['color'],
        //     }
    }

    interface ThemeOptions {
        //     endpointVerb?: {
        //         get?: React.CSSProperties['color'],
        //         post?: React.CSSProperties['color'],
        //         delete?: React.CSSProperties['color'],
        //     }
    }
}

export default function createFbTheme(options: ThemeOptions) {
    return createMuiTheme({
        ...options,
    });
}