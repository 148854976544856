import React from 'react';
import {Box, createStyles, Divider, Grid, Theme, Typography} from '@material-ui/core';
import AttributeList from './AttributeList';
import JsonObjectCard, {Json} from './JsonObjectCard';
import {Attribute} from './ObjectAttribute';
import {makeStyles} from '@material-ui/core/styles';

export interface ObjectData {
    title: string,
    attributes: Attribute[],
    jsonObject: Json,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sticky: {
            position: 'sticky',
            top: '6rem',
        },
        mdHidden: {
            [theme.breakpoints.up('md')]: {
                visibility: 'hidden',
            }
        },
        title: {
            fontWeight: 'bold',
        }
    })
);

interface Props {
    data: ObjectData,
}

const ObjectDefinition: React.FC<Props> = ({ data: { title, attributes, jsonObject } }) => {
    const classes = useStyles();

    return (
        <>
            <Box mb={ 3 }>
                <Typography variant="h5" className={ classes.title }>{ title }</Typography>
            </Box>
            <Grid container spacing={ 3 }>
                <Grid item md={ 6 } xs={ 12 }>
                    <AttributeList attributes={ attributes }/>

                    <Divider className={ classes.mdHidden }/>
                </Grid>
                <Grid item md={ 6 } xs={ 12 }>
                    <Box className={ classes.sticky } mb={ 3 }>
                        <JsonObjectCard jsonData={ jsonObject }/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ObjectDefinition;
