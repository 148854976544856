import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const moveInventoryRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/parts/:id/inventory/move',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/parts/:id/inventory/move' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<INVENTORY JSON>'
`,
};

const moveInventoryResponse: Json = {
    title: 'RESPONSE',
    json: {},
};

const moveInventoryAttributes: Attribute[] = [
    {
        name: 'sourceLocation',
        type: 'reference object',
        optional: true,
        description: 'The source location where the inventory will be moved from.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'location',
        type: 'reference object',
        optional: true,
        description: 'The end location where the inventory will be moved to.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'quantity',
        type: 'quantity',
        description: 'The quantity of the part to move to a different location.'
    },
    {
        name: 'uom',
        type: 'reference object',
        optional: true,
        description: 'The part unit of measure.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'note',
        type: 'string',
        optional: true,
        description: 'A note about the inventory adjustment.',
    },
    {
        name: 'customer',
        type: 'reference object',
        optional: true,
        description: 'The customer associated with the inventory adjustment.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'class',
        type: 'reference object',
        optional: true,
        description: 'The class category for the inventory adjustment.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'date',
        type: 'date',
        optional: true,
        description: 'The date for the inventory adjustment. If blank, the current date will be used.',
    },
    {
        name: 'trackingItems',
        type: 'list',
        optional: true,
        description: 'A list of the tracking items for the inventory. Only used if the part tracks inventory.',
        children: [
            {
                name: 'partTracking',
                type: 'reference object',
                optional: true,
                description: 'The type of tracking being set.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'value',
                type: 'string',
                optional: true,
                description: 'The tracking value.'
            },
            {
                name: 'serialNumbers',
                type: 'list',
                optional: true,
                description: 'A list of serial numbers.',
                children: [
                    {
                        name: 'numbers',
                        type: 'list',
                        description: 'A list of serial number values.',
                        children: [
                            {
                                name: 'partTracking',
                                type: 'reference object',
                                description: 'The type of tracking being set.',
                                children: [
                                    {
                                        name: 'id',
                                        type: 'integer',
                                        description: 'The unique identification number.',
                                    },
                                ]
                            },
                            {
                                name: 'value',
                                type: 'string',
                                description: 'The serial number value.'
                            },
                        ]
                    }
                ]
            }
        ]
    },
];

const data: RequestDefinitionData = {
    callName: 'move-inventory',
    title: 'Move Inventory',
    description: 'Moves inventory from one location to another.',
    attributes: moveInventoryAttributes,
    requestObject: moveInventoryRequest,
    responseObject: moveInventoryResponse,
};

export default data;
