import React, {useEffect} from 'react';
import {RouteComponent} from '../RouteComponent';

import SideMenu from './components/main/SideMenu';
import FbAppBar from './components/main/FbAppBar';
import DocumentContent from './components/main/DocumentContent';
import {makeStyles} from '@material-ui/core/styles';
import {createStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
    })
);

export default new RouteComponent('/apidocs', () => {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Fishbowl REST Api Documentation';
    }, []);

    return (
        <div className={ classes.root }>
            <FbAppBar/>
            <SideMenu/>
            <DocumentContent/>
        </div>
    );
});
