import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const updateUomRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/uoms/:id',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/uoms/:id' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<UOM JSON>'
`,
};

const updateUomResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 25,
        "name": "box",
        "abbreviation": "bx",
        "description": "A cardboard container",
        "type": "Count",
        "active": true,
        "integral": false,
        "readOnly": false,
        "conversions": [
            {
                "id": 37,
                "description": "1 box = 15.0 Each",
                "targetUom": {
                    "id": 1,
                    "name": "Each",
                    "abbreviation": "ea"
                },
                "divisor": "15",
                "multiplier": "1"
            }
        ]
    },
};

const createUomAttributes: Attribute[] = [
    {
        name: 'name',
        type: 'string',
        description: 'The UOM name.',
    },
    {
        name: 'abbreviation',
        type: 'string',
        description: 'The UOM abbreviation.',
    },
    {
        name: 'description',
        type: 'string',
        description: 'The UOM description.',
    },
    {
        name: 'active',
        type: 'boolean',
        optional: true,
        description: 'The active status of the UOM.',
    },
    {
        name: 'readOnly',
        type: 'boolean',
        optional: true,
        description: 'Indicates if the UOM is read-only.',
    },
    {
        name: 'conversions',
        type: 'list',
        optional: true,
        description: 'A list of the UOM conversions. Any existing conversions will be deleted if the field is not included or the list is blank.',
        children: [
            {
                name: 'targetUom',
                type: 'reference object',
                description: 'The resulting UOM of the conversion.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'divisor',
                type: 'quantity',
                description: 'Divide the quantity by the divisor to convert to the target UOM.',
            },
            {
                name: 'multiplier',
                type: 'quantity',
                description: 'Multiply the quantity by the multiplier to convert to the target UOM.',
            },
        ]
    }
];

const data: RequestDefinitionData = {
    callName: 'update-uom',
    title: 'Update a unit of measure',
    description: 'Update a unit of measure.',
    attributes: createUomAttributes,
    requestObject: updateUomRequest,
    responseObject: updateUomResponse,
};

export default data;
