import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';
import {Attribute} from '../../components/ObjectAttribute';

const createManufactureOrderRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/manufacture-orders',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/manufacture-orders' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<MO JSON>'
`,
};

const createManufactureOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 41,
        "number": "1032",
        "revisionNumber": 1,
        "locationGroup": {
            "id": 5,
            "name": "SLC"
        },
        "dateCreated": "2021-05-28T14:26:59.239-0600",
        "lastModified": {
            "username": "admin",
            "dateLastModified": "2021-05-28T14:26:59.242-0600"
        },
        "dateScheduled": "2021-05-28T14:26:59.200-0600",
        "url": "",
        "percentComplete": "0%",
        "status": "Entered",
        "class": {
            "id": 1,
            "name": "None"
        },
        "configurations": [
            {
                "id": 398,
                "bom": {
                    "id": 3,
                    "name": "LP100"
                },
                "priceAdjustment": "0",
                "unitCost": "0",
                "dateScheduled": "2021-05-28T14:26:59.000-0600",
                "dateScheduledToStart": "2021-05-28T13:56:59.000-0600",
                "sortId": 1,
                "stageLevel": 1,
                "hasSo": false,
                "status": "Entered",
                "quantity": "5",
                "quantityFulfilled": "0",
                "class": {
                    "id": 8,
                    "name": "LA"
                },
                "priority": "3-Normal",
                "items": [
                    {
                        "id": 399,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 1,
                        "part": {
                            "id": 48,
                            "name": "LP100",
                            "description": "Custom License Plate"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "5",
                        "quantityFulfilled": "0",
                        "type": "Finished Good"
                    },
                    {
                        "id": 400,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 2,
                        "part": {
                            "id": 48,
                            "name": "LP100",
                            "description": "Custom License Plate"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "5",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    },
                    {
                        "id": 401,
                        "stage": false,
                        "priceAdjustment": "0",
                        "unitCost": "0",
                        "sortId": 3,
                        "part": {
                            "id": 51,
                            "name": "OC-LP100",
                            "description": "License Plate Manufacturing Cost"
                        },
                        "uom": {
                            "id": 1,
                            "name": "Each",
                            "abbreviation": "ea"
                        },
                        "oneTimeItem": false,
                        "status": "Entered",
                        "quantity": "5",
                        "quantityFulfilled": "0",
                        "type": "Raw Good"
                    }
                ]
            }
        ],
        "customFields": []
    },
};

const createManufactureOrderAttributes: Attribute[] = [
    {
        name: 'number',
        type: 'string',
        optional: true,
        description: 'The manufacture order number.'
    },
    {
        name: 'status',
        type: 'string',
        optional: true,
        description: 'The order status.'
    },
    {
        name: 'note',
        type: 'string',
        optional: true,
        description: 'The order\'s note field.',
    },
    {
        name: 'locationGroup',
        type: 'reference object',
        optional: true,
        description: 'The location group the order belongs to.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'dateScheduled',
        type: 'date',
        optional: true,
        description: 'Timestamp of when the order is scheduled to be fulfilled.',
    },
    {
        name: 'url',
        type: 'string',
        optional: true,
        description: 'The url link on the order.',
    },
    {
        name: 'class',
        type: 'reference object',
        optional: true,
        description: 'The class category.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
        ]
    },
    {
        name: 'configurations',
        type: 'list',
        description: 'A list of the manufacture order configurations.',
        children: [
            {
                name: 'bom',
                type: 'reference object',
                optional: true,
                description: 'The bill of materials associated with the configuration. If no configuration items are included, the MO will be created with the BOM default values.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.'
                    },
                ]
            },
            {
                name: 'quantity',
                type: 'quantity',
                description: 'The quantity to be fulfilled.',
            },
            {
                name: 'dateScheduled',
                type: 'date',
                optional: true,
                description: 'Timestamp of when the configuration was scheduled.',
            },
            {
                name: 'dateScheduledToStart',
                type: 'date',
                optional: true,
                description: 'Timestamp of when the configuration was scheduled to start.',
            },
            {
                name: 'note',
                type: 'string',
                optional: true,
                description: 'The item\'s note field.',
            },
            {
                name: 'class',
                type: 'reference object',
                optional: true,
                description: 'The class category.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                ]
            },
            {
                name: 'priority',
                type: 'string',
                optional: true,
                description: 'The name of the priority level.',
            },
            {
                name: 'items',
                type: 'list',
                optional: true,
                description: 'A list of the manufacture order items. These items are used when specifying a configuration.',
                children: [
                    {
                        name: 'description',
                        type: 'string',
                        description: 'The description of the manufacture order item.',
                    },
                    {
                        name: 'type',
                        type: 'string: \'Raw Good\' | \'Finished Good\'',
                        description: 'The type of manufacture order item.',
                    },
                    {
                        name: 'part',
                        type: 'reference object',
                        description: 'The part on the order item.',
                        children: [
                            {
                                name: 'id',
                                type: 'integer',
                                description: 'The unique identification number.',
                            },
                        ]
                    },
                    {
                        name: 'uom',
                        type: 'reference object',
                        description: 'The UOM of the order item.',
                        children: [
                            {
                                name: 'id',
                                type: 'integer',
                                description: 'The unique identification number.',
                            },
                        ]
                    },
                    {
                        name: 'oneTimeItem',
                        type: 'boolean',
                        optional: true,
                        description: 'Specifies the item as a one time item for the order.',
                    },
                    {
                        name: 'quantity',
                        type: 'quantity',
                        description: 'The quantity to be fulfilled.',
                    },
                ]
            },
        ]
    },
    {
        name: 'customFields',
        type: 'list',
        optional: true,
        description: 'A list of the order\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The custom field\'s unique identifier.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    },
];

const data: RequestDefinitionData = {
    callName: 'create-manufacture-order',
    title: 'Create a manufacture order',
    description: 'Creates a manufacture order.',
    attributes: createManufactureOrderAttributes,
    requestObject: createManufactureOrderRequest,
    responseObject: createManufactureOrdersResponse,
};

export default data;
