import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Box, Grid, Link, Typography} from '@material-ui/core';
import Endpoints, {Endpoint} from './Endpoints';

export interface HeaderData {
    title: string,
    description: string,
    linkName?: string,
    link?: string,
    endpoints: Endpoint[],
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacing: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            }
        }
    })
);

interface Props {
    data: HeaderData,
    children?: React.ReactNode,
}

const ObjectHeader: React.FC<Props> = ({ data: { title, description, linkName, link, endpoints }, children }) => {
    const classes = useStyles();

    return (
        <>
            <Box mb={ 3 }>
                <Typography variant="h4">{ title }</Typography>
            </Box>
            <Grid container spacing={ 3 }>
                <Grid item md={ 6 } xs={ 12 }>

                    <Box mb={ 3 }>
                        <Typography>
                            { description }
                        </Typography>
                    </Box>
                    { children &&
                        <Box mb={ 3 }>
                            { children }
                        </Box>
                    }
                    { link &&
                        <Box mb={ 3 } className={ classes.spacing }>
                            <Typography>
                                Related guide: <Link target="_blank" href={ link }>{ linkName }</Link>
                            </Typography>
                        </Box>
                    }
                </Grid>
                <Grid item md={ 6 } xs={ 12 }>
                    <Box mb={ 3 }>
                        <Endpoints endpoints={ endpoints }/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ObjectHeader;
