import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Box, Link, Paper, Tooltip, Typography} from '@material-ui/core';

export interface Endpoint {
    type: string,
    endpoint: string,
    link: string,
    tooltip: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: '10px',
        },
        paperHeader: {
            backgroundColor: 'grey',
            borderRadius: '10px 10px 0 0',
            paddingLeft: '.9rem'
        },
        paperContent: {
            padding: '.5rem'
        },
        get: {
            color: 'blue'
        },
        post: {
            color: 'green'
        },
        delete: {
            color: 'red'
        },
        link: {
            color: theme.palette.text.secondary,
            '&:hover': {
                color: theme.palette.text.primary
            },
        },
    })
);

interface Props {
    endpoints: Endpoint[],
}

const Endpoints: React.FC<Props> = ({ endpoints }) => {
    const classes = useStyles();

    return (
        <Paper variant="outlined" className={ classes.paper }>
            <div className={ classes.paperHeader }>
                <Typography variant="subtitle1">ENDPOINTS</Typography>
            </div>
            <div className={ classes.paperContent }>
                { endpoints.map(({ type, endpoint, link, tooltip }, index) => (
                    <Box key={ index } mt={ index > 0 ? 1 : 0 }>
                        <Link href={ link } className={ classes.link } underline="none">
                            <Tooltip title={ tooltip } placement="top" arrow>
                                <Typography variant="body2" component="span"><span
                                    className={ type }>{ type }</span> /api{ endpoint }</Typography>
                            </Tooltip>
                        </Link>
                    </Box>
                )) }
            </div>
        </Paper>
    );
};

export default Endpoints;
