import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from "../../components/RequestDefinition";

const closeShortPurchaseOrdersRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/purchase-orders/:id/close-short',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/purchase-orders/:id/close-short' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const closeShortPurchaseOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 83,
        "number": "200",
        "carrier": {
            "id": 1,
            "name": "Will Call"
        },
        "fobPointName": "Origin",
        "paymentTerms": {
            "id": 1,
            "name": "COD"
        },
        "vendor": {
            "id": 22,
            "name": "A&B Distribution"
        },
        "vendorSoNumber": "",
        "customerSoNumber": "",
        "vendorContact": "A&B Distribution",
        "buyer": {
            "id": 1,
            "name": "admin"
        },
        "deliverTo": "",
        "revisionNumber": 4,
        "lastModified": {
            "username": "admin",
            "dateLastModified": "2021-03-26T11:24:27.393-0600"
        },
        "issuedByUser": {
            "username": "admin",
            "dateLastModified": "2021-03-17T09:52:45.346-0600"
        },
        "dateCreated": "2021-03-17T00:00:00.000-0600",
        "dateScheduled": "2021-03-17T00:00:00.000-0600",
        "dateCompleted": "2021-03-26T11:24:27.388-0600",
        "totalTax": "0",
        "totalCost": "5",
        "mcTotalTax": "0",
        "mcTotalCost": "5",
        "totalIncludesTax": false,
        "locationGroup": {
            "id": 5,
            "name": "SLC"
        },
        "note": "",
        "url": "",
        "currency": {
            "id": 1,
            "name": "US Dollar",
            "code": "USD",
            "rate": "1",
            "homeCurrency": true
        },
        "email": "",
        "phone": "801-932-1101",
        "shipToAddress": {
            "name": "Fishbowl Bikes",
            "street": "580 Technology Way",
            "city": "Orem",
            "state": "UT",
            "postalCode": "84097",
            "country": "UNITED STATES"
        },
        "remitToAddress": {
            "name": "A&B Distribution",
            "street": "432 Vine St.",
            "city": "Los Angeles",
            "state": "CA",
            "postalCode": "91775",
            "country": "UNITED STATES"
        },
        "status": "Closed Short",
        "class": {
            "id": 1,
            "name": "None"
        },
        "shipTerms": "Prepaid & Billed",
        "type": "Standard",
        "poItems": [
            {
                "id": 194,
                "part": {
                    "id": 28,
                    "name": "B201",
                    "description": "Premium Brake Cables"
                },
                "type": {
                    "id": 10,
                    "name": "Purchase"
                },
                "uom": {
                    "id": 1,
                    "name": "Each",
                    "abbreviation": "ea"
                },
                "lineNumber": 1,
                "vendorPartNumber": "B201",
                "unitCost": "5",
                "totalTax": "0",
                "totalCost": "5",
                "mcTotalTax": "0",
                "mcTotalCost": "5",
                "taxRate": {
                    "id": 1,
                    "name": "None",
                    "rate": 0.0
                },
                "dateLastFulfilled": "2021-03-26T11:24:27.381-0600",
                "dateScheduled": "2021-03-17T09:52:24.126-0600",
                "revision": "",
                "note": "",
                "status": "Closed Short",
                "quantity": "1",
                "quantityFulfilled": "1",
                "quantityPicked": "0",
                "class": {
                    "id": 1,
                    "name": "None"
                },
                "customFields": []
            }
        ],
        "customFields": [
            {
                'id': 19,
                'name': 'Custom',
                'value': 'My Custom Field',
                'type': 'Text'
            }
        ]
    },
};

const data: RequestDefinitionData = {
    callName: 'close-short-purchase-order',
    title: 'Close short a purchase order',
    description: 'Close shorts the purchase order with the specified ID.',
    attributes: [],
    requestObject: closeShortPurchaseOrdersRequest,
    responseObject: closeShortPurchaseOrdersResponse,
};

export default data;
