import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const searchVendorsRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/vendors',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/vendors' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const searchVendorsResponse: Json = {
    title: 'RESPONSE',
    json: {
        "totalCount": 3,
        "totalPages": 1,
        "pageNumber": 1,
        "pageSize": 100,
        "results": [
            {
                "id": 16,
                "name": "Mike's Bikes",
                "accountNumber": "216489",
                "active": true
            },
            {
                "id": 24,
                "name": "Monroe Bike Company",
                "accountNumber": "5976",
                "active": true
            },
            {
                "id": 21,
                "name": "Rocky Mountain Bikes",
                "accountNumber": "98979",
                "active": true
            }
        ]
    },
};

const searchVendorsQueryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'name',
        type: 'string',
        description: 'The vendor name.',
    },
    {
        name: 'accountNumber',
        type: 'string',
        description: 'The vendor account number.',
    },
    {
        name: 'city',
        type: 'string',
        description: 'The vendor address city.',
    },
    {
        name: 'state',
        type: 'string',
        description: 'The vendor address state.',
    },
    {
        name: 'country',
        type: 'string',
        description: 'The vendor address country',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'Indicates if the vendor is active.',
    },
];

const searchVendorsAttributes: Attribute[] = [
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the vendor\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the custom field.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    }
];

const data: RequestDefinitionData = {
    callName: 'search-vendor',
    title: 'Search for vendors',
    description: 'Searches for vendors.',
    attributes: searchVendorsAttributes,
    requestObject: searchVendorsRequest,
    responseObject: searchVendorsResponse,
    queryParameters: searchVendorsQueryParameters,
}

export default data;
