import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const purchaseOrdersEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/purchase-orders',
        link: '#search-purchase-order',
        tooltip: 'Search for purchase orders',
    },
    {
        type: 'GET',
        endpoint: '/purchase-orders/:id',
        link: '#get-purchase-order',
        tooltip: 'Get a purchase order',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders',
        link: '#create-purchase-order',
        tooltip: 'Create a purchase order',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders/:id',
        link: '#update-purchase-order',
        tooltip: 'Update a purchase order',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders/:id/issue',
        link: '#issue-purchase-order',
        tooltip: 'Issue a purchase order',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders/:id/unissue',
        link: '#unissue-purchase-order',
        tooltip: 'Unissue a purchase order',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders/:id/close-short',
        link: '#close-short-purchase-order',
        tooltip: 'Close short a purchase order',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders/:id/close-short/:poItemId',
        link: '#close-short-purchase-order-item',
        tooltip: 'Close short a purchase order item',
    },
    {
        type: 'POST',
        endpoint: '/purchase-orders/:id/void',
        link: '#void-purchase-order',
        tooltip: 'Void a purchase order',
    },
    {
        type: 'DELETE',
        endpoint: '/purchase-orders/:id',
        link: '#delete-purchase-order',
        tooltip: 'Delete a purchase order',
    }
];

export const purchaseOrdersHeader: HeaderData = {
    title: 'Purchase Orders',
    description: 'This is an object representing a Fishbowl purchase order. Purchase orders are used ' +
        'to buy inventory items from your vendors.',
    linkName: 'Purchase Orders',
    link: 'https://www.fishbowlinventory.com/wiki/Purchase_Order',
    endpoints: purchaseOrdersEndpoints,
};

export const jsonObject: Json = {
    title: 'THE PURCHASE ORDER OBJECT',
    json: {
        'id': 72,
        'number': '50071',
        'carrier': {
            'id': 5,
            'name': 'USPS',
        },
        'fobPointName': 'Origin',
        'paymentTerms': {
            'id': 4,
            'name': 'Net 30'
        },
        'vendor': {
            'id': 17,
            'name': 'Brent\'s Bicycle Brakes'
        },
        'vendorSoNumber': 'S108',
        'customerSoNumber': '10086',
        'vendorContact': 'Brent\'s Bicycle Brakes',
        'buyer': {
            'id': 1,
            'name': 'admin'
        },
        'deliverTo': 'Allan\'s Raceway',
        'revisionNumber': 3,
        'lastModified': {
            'username': 'admin',
            'dateLastModified': '2021-02-24T13:49:20.493-0700'
        },
        'issuedByUser': {
            'username': 'admin',
            'dateLastModified': '2021-02-24T13:49:20.490-0700'
        },
        'dateCreated': '2020-11-18T00:00:00.000-0700',
        'dateConfirmed': '2020-11-18T15:44:40.886-0700',
        'dateRevision': '2020-11-18T15:44:40.839-0700',
        'dateScheduled': '2020-11-18T00:00:00.000-0700',
        'dateCompleted': '2020-11-20T00:00:00.000-0700',
        'taxRate': {
            'id': 0,
            'name': 'None'
        },
        'totalTax': '0',
        'totalIncludesTax': false,
        'locationGroup': {
            'id': 5,
            'name': 'SLC'
        },
        'note': 'Created for SO #10086',
        'url': '',
        'currency': {
            'id': 1,
            'name': 'US Dollar',
            'code': 'USD',
            'rate': '1',
            'homeCurrency': true
        },
        'email': 'brent@example.com',
        'phone': '801-932-1101',
        'shipToAddress': {
            'name': 'Allan\'s Raceway',
            'street': '2222 Wrecker\'s Blvd',
            'city': 'Santa Barbara',
            'state': 'CA',
            'postalCode': '93101',
            'country': 'UNITED STATES'
        },
        'remitToAddress': {
            'name': 'Brent\'s Bicycle Brakes',
            'street': '777 South Delivery Lane',
            'city': 'Salt Lake City',
            'state': 'UT',
            'postalCode': '84111',
            'country': 'UNITED STATES'
        },
        'status': 'Issued',
        'class': {
            'id': 12,
            'name': 'LA:LA Store'
        },
        'shipTerms': 'Prepaid & Billed',
        'type': 'Drop Ship',
        'poItems': [
            {
                'id': 182,
                'part': {
                    'id': 28,
                    'name': 'B201',
                    'description': 'Premium Brake Cables'
                },
                'type': {
                    'id': 10,
                    'name': 'Purchase'
                },
                'lineNumber': 1,
                'vendorPartNumber': 'B1-7878',
                'quantity': '1',
                'quantityFulfilled': '0',
                'quantityPicked': '0',
                'unitCost': '9',
                'totalCost': '9',
                'mcTotalCost': '9',
                'taxRate': {
                    'id': 1,
                    'name': 'None',
                    'rate': 0.0
                },
                'dateScheduledFulfillment': '2020-11-18T15:44:34.096-0700',
                'revision': '',
                'note': '',
                'customer': {
                    'id': 52,
                    'name': 'Allan\'s Raceway'
                },
                'uom': {
                    'id': 1,
                    'name': 'Each',
                    'abbreviation': 'ea'
                },
                'status': 'Entered',
                'class': {
                    'id': 12,
                    'name': 'LA:LA Store'
                },
                'customFields': []
            }
        ],
        'customFields': [
            {
                'id': 19,
                'name': 'Custom',
                'value': 'My Custom Field',
                'type': 'Text'
            }
        ]
    },
};

export const purchaseOrderAttributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The purchase order\'s unique identification number.'
    },
    {
        name: 'number',
        type: 'string',
        description: 'The purchase order number.'
    },
    {
        name: 'status',
        type: 'string',
        description: 'The order status.'
    },
    {
        name: 'class',
        type: 'reference object',
        description: 'The class category.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'carrier',
        type: 'reference object',
        description: 'The shipping carrier.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            },
            {
                name: 'service',
                type: 'reference object',
                optional: true,
                description: 'The carrier service.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                ]
            }
        ]
    },
    {
        name: 'fobPointName',
        type: 'string',
        description: 'Indicates when ownership/liability of the order transfers to the purchaser.'
    },
    {
        name: 'paymentTerms',
        type: 'reference object',
        description: 'The terms of payment on the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'shipTerms',
        type: 'string',
        description: 'The shipping terms on the order.',
    },
    {
        name: 'type',
        type: 'string: \'Standard\' | \'Drop Ship\'',
        description: 'Indicates whether the order is a standard or drop ship purchase order.',
    },
    {
        name: 'vendor',
        type: 'reference object',
        description: 'The vendor on the purchase order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'vendorSoNumber',
        type: 'string',
        description: 'The vendor sales order number.',
    },
    {
        name: 'customerSoNumber',
        type: 'string',
        description: 'The customer sales order number.',
    },
    {
        name: 'buyer',
        type: 'reference object',
        description: 'The Fishbowl user that created the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'deliverTo',
        type: 'string',
        description: 'The intended recipient of the order.',
    },
    {
        name: 'revisionNumber',
        type: 'integer',
        description: 'The revision number.',
    },
    {
        name: 'lastModified',
        type: 'object',
        description: 'Timestamp of when the order was last modified and the user that made the modifications.',
        children: [
            {
                name: 'username',
                type: 'string',
                description: 'The username of the user last modified.',
            },
            {
                name: 'dateLastModified',
                type: 'date',
                description: 'The date last modified.',
            }
        ]
    },
    {
        name: 'issuedByUser',
        type: 'object',
        description: 'Timestamp of when the order was issued and the user that made the modifications.',
        children: [
            {
                name: 'username',
                type: 'string',
                description: 'The username of the user that issued the order.',
            },
            {
                name: 'dateLastModified',
                type: 'date',
                description: 'The date issued.',
            }
        ]
    },
    {
        name: 'dateCreated',
        type: 'date',
        description: 'Timestamp of when the order was created.',
    },
    {
        name: 'dateConfirmed',
        type: 'date',
        description: 'Timestamp of when the order was confirmed by the vendor.',
    },
    {
        name: 'dateRevision',
        type: 'date',
        description: 'Timestamp of when the order was last revised.',
    },
    {
        name: 'dateScheduled',
        type: 'date',
        description: 'Timestamp of when the order was scheduled.',
    },
    {
        name: 'dateCompleted',
        type: 'date',
        description: 'Timestamp of when the order was completed.',
    },
    {
        name: 'taxRate',
        type: 'reference object',
        description: 'The tax rate on the order. This object is ignored for companies in the United States.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            },
            {
                name: 'rate',
                type: 'double',
                description: 'The tax rate.',
            },
        ]
    },
    {
        name: 'totalTax',
        type: 'money',
        description: 'The total amount of tax on the order.',
    },
    {
        name: 'totalIncludesTax',
        type: 'boolean',
        description: 'Indicates if the order total includes tax.',
    },
    {
        name: 'locationGroup',
        type: 'reference object',
        description: 'The location group the order belongs to.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'note',
        type: 'string',
        description: 'The order\'s note field.',
    },
    {
        name: 'url',
        type: 'string',
        description: 'The url link on the order.',
    },
    {
        name: 'currency',
        type: 'reference object',
        description: 'The currency used on the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            },
            {
                name: 'code',
                type: 'string',
                description: 'The currency code.',
            },
            {
                name: 'rate',
                type: 'number',
                description: 'The currency exchange rate.',
            },
            {
                name: 'homeCurrency',
                type: 'boolean',
                description: 'Indicates if the currency is the home currency.',
            }
        ]
    },
    {
        name: 'email',
        type: 'string',
        description: 'The vendor\'s email address for the order.',
    },
    {
        name: 'phone',
        type: 'string',
        description: 'The vendor\'s phone number for the order.',
    },
    {
        name: 'shipToAddress',
        type: 'object',
        description: 'The shipping address on the order.',
        children: [
            {
                name: 'name',
                type: 'string',
                description: 'The name on the address.',
            },
            {
                name: 'street',
                type: 'string',
                description: 'The street line.',
            },
            {
                name: 'city',
                type: 'string',
                description: 'The city.',
            },
            {
                name: 'state',
                type: 'string',
                description: 'The state\'s abbreviation.',
            },
            {
                name: 'postalCode',
                type: 'string',
                description: 'The postal code.',
            },
            {
                name: 'country',
                type: 'string',
                description: 'The country\'s abbreviation.',
            }
        ]
    },
    {
        name: 'remitToAddress',
        type: 'object',
        description: 'The remit to address on the order.',
        children: [
            {
                name: 'name',
                type: 'string',
                description: 'The name on the address.',
            },
            {
                name: 'street',
                type: 'string',
                description: 'The street line.',
            },
            {
                name: 'city',
                type: 'string',
                description: 'The city.',
            },
            {
                name: 'state',
                type: 'string',
                description: 'The state\'s abbreviation.',
            },
            {
                name: 'postalCode',
                type: 'string',
                description: 'The postal code.',
            },
            {
                name: 'country',
                type: 'string',
                description: 'The country\'s abbreviation.',
            }
        ]
    },
    {
        name: 'poItems',
        type: 'list',
        description: 'A list of the purchase order items.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the order item.',
            },
            {
                name: 'part',
                type: 'reference object',
                description: 'The part on the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                    {
                        name: 'description',
                        type: 'string',
                        description: 'The description of the object.',
                    },
                ]
            },
            {
                name: 'outsourcedPart',
                type: 'reference object',
                description: 'The outsourced part on the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                    {
                        name: 'description',
                        type: 'string',
                        description: 'The description of the object.',
                    },
                ]
            },
            {
                name: 'type',
                type: 'reference object',
                description: 'The order item\'s type.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    }
                ]
            },
            {
                name: 'status',
                type: 'string',
                description: 'The status of the order item.',
            },
            {
                name: 'uom',
                type: 'reference object',
                description: 'The part unit of measure.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                    {
                        name: 'abbreviation',
                        type: 'string',
                        description: 'The abbreviated name.',
                    }
                ]
            },
            {
                name: 'lineNumber',
                type: 'integer',
                description: 'The line number for the order item.',
            },
            {
                name: 'vendorPartNumber',
                type: 'string',
                description: 'The vendor\'s number for the part.',
            },
            {
                name: 'quantity',
                type: 'quantity',
                description: 'The quantity to be fulfilled.',
            },
            {
                name: 'quantityFulfilled',
                type: 'quantity',
                description: 'The quantity already fulfilled.',
            },
            {
                name: 'quantityPicked',
                type: 'quantity',
                description: 'The quantity already picked.',
            },
            {
                name: 'unitCost',
                type: 'money',
                description: 'The unit cost of the part.',
            },
            {
                name: 'totalTax',
                type: 'money',
                description: 'The total tax of the part.',
            },
            {
                name: 'totalCost',
                type: 'money',
                description: 'The total cost of the part.',
            },
            {
                name: 'mcTotalTax',
                type: 'money',
                description: 'The multi-currency total tax of the part.',
            },
            {
                name: 'mcTotalCost',
                type: 'money',
                description: 'The multi-currency total cost of the part.',
            },
            {
                name: 'taxRate',
                type: 'reference object',
                description: 'The tax rate for the order item. This object is ignored for companies in the United States.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                    {
                        name: 'rate',
                        type: 'double',
                        description: 'The tax rate.',
                    },
                ]
            },
            {
                name: 'dateLastFulfilled',
                type: 'date',
                description: 'The most recent fulfilled date for a partially or completely fulfilled order item.',
            },
            {
                name: 'dateScheduled',
                type: 'date',
                description: 'Timestamp of when the order is scheduled to be fulfilled.',
            },
            {
                name: 'revision',
                type: 'string',
                description: 'The revision number for the order item.',
            },
            {
                name: 'class',
                type: 'reference object',
                description: 'The class category for the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                ]
            },
            {
                name: 'note',
                type: 'string',
                description: 'The note on the order item.',
            },
            {
                name: 'customer',
                type: 'reference object',
                description: 'The customer on the order item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The unique identification number.',
                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the object.',
                    },
                ]
            },
            {
                name: 'customFields',
                type: 'list',
                description: 'A list of custom fields associated with the line item.',
                children: [
                    {
                        name: 'id',
                        type: 'integer',
                        description: 'The custom field\'s unique identifier.',

                    },
                    {
                        name: 'name',
                        type: 'string',
                        description: 'The name of the custom field.',
                    },
                    {
                        name: 'value',
                        type: 'string',
                        description: 'The custom field\'s value.'
                    },
                    {
                        name: 'type',
                        type: 'string',
                        description: 'The category of the custom field\'s value.'
                    },
                ]
            },
        ]
    },
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of custom fields associated with the order.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The custom field\'s unique identifier.',

            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the custom field.',
            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
            {
                name: 'type',
                type: 'string',
                description: 'The category of the custom field\'s value.'
            },
        ]
    },
];
