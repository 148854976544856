import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';

export interface Json {
    title: string,
    json: object | string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: '10px',
        },
        paperHeader: {
            backgroundColor: 'grey',
            borderRadius: '10px 10px 0 0',
            paddingLeft: '.9rem'
        },
        paperContent: {
            padding: '.5rem',
            maxHeight: '88vh',
            overflowY: 'auto',
        },
    })
);

interface Props {
    jsonData: Json,
}

const JsonObjectCard: React.FC<Props> = ({ jsonData: { title, json } }) => {
    const classes = useStyles();

    return (
        <Paper variant="outlined" className={ classes.paper }>
            <div className={ classes.paperHeader }>
                <Typography variant="subtitle1">{ title }</Typography>
            </div>
            <div className={ classes.paperContent }>
                <pre>
                    { (typeof json === 'string') ? json : JSON.stringify(json, null, 2) }
                </pre>
            </div>
        </Paper>
    );
};

export default JsonObjectCard;
