import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const createPluginInfoRequest: RequestExample = {
    title: {
        verb: 'POST',
        endpointUrl: '/api/integrations/plugin-info',
    },
    curl:
        `curl --location \\
--request POST '<YOUR SERVER>/api/integrations/plugin-info' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '<PLUGIN INFO JSON>'
`,
};
const createPluginInfoResponse: Json = {
    title: 'RESPONSE',
    json: [
        {
            "id": 31,
            "plugin": "Fishbowl Time",
            "table": "WO",
            "recordId": 72,
            "groupId": 10,
            "channelId": "b674f2bf-7ea2-491f-8d70-8cf4fd3243fc"
        },
        {
            "id": 32,
            "plugin": "Fishbowl Time",
            "table": "WO",
            "recordId": 72,
            "groupId": 20,
            "channelId": "27d1891e-0627-4abf-87a6-9a38db5d78f2",
            "info": {
                "id": 123
            }
        }
    ]
};

const createPluginInfoParameters: Attribute[] = [
    {
        name: 'plugin',
        type: 'string',
        description: 'The name of the plugin.',
    },
    {
        name: 'authorization',
        type: 'string',
        description: 'The user specified code for authorization.',
    },
];

const createPluginInfoAttributes: Attribute[] = [
    {
        name: 'groupId',
        type: 'int',
        optional: true,
        description: 'The user specified identifier used to separate types of plugins you are using.',
    },
    {
        name: 'channelId',
        type: 'string',
        optional: true,
        description: 'The unique identifier for the object being linked externally.',
    },
    {
        name: 'recordId',
        type: 'int',
        optional: true,
        description: 'The unique identifier for a record in Fishbowl associated with the table.',
    },
    {
        name: 'table',
        type: 'string',
        optional: true,
        description: 'The table name in the database.',
    }
];

const data: RequestDefinitionData = {
    callName: 'create-plugin-info',
    title: 'Create a plugin information',
    description: 'Creates a plugin information.',
    queryParameters: createPluginInfoParameters,
    attributes: createPluginInfoAttributes,
    requestObject: createPluginInfoRequest,
    responseObject: createPluginInfoResponse,}

export default data;
