import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import ObjectDefinition from '../ObjectDefinition';
import RequestDefinition from '../RequestDefinition';
import {jsonObject, userAttributes, usersHeader} from '../../data/users/user';
import searchUsersRequest from '../../data/users/searchUsersRequest';

export default new RouteComponent(`${apiRoutePrefix}/users`, () => {

    return (
        <div>
            <ObjectHeader data={ usersHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The user object',
                attributes: userAttributes,
                jsonObject: jsonObject
            } }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <RequestDefinition {...searchUsersRequest}/>
        </div>
    );
});
