import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const endpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/location-groups',
        link: '#search-location-group',
        tooltip: 'Search for location groups',
    },
];

export const header: HeaderData = {
    title: 'Location Groups',
    description: 'This is an object representing a Fishbowl location group.',
    linkName: 'Location Groups',
    link: 'https://www.fishbowlinventory.com/wiki/Location_Group',
    endpoints: endpoints,
};

export const jsonObject: Json = {
    title: 'THE LOCATION GROUP OBJECT',
    json: {
        id: 1,
        name: "Main",
        class: {
            id: 0,
            name: "",
        },
        active: true,
    },
};

export const attributes: Attribute[] = [
    {
        name: 'id',
        type: 'integer',
        description: 'The location group\'s unique identification number.'
    },
    {
        name: 'name',
        type: 'string',
        description: 'The location group\'s name.'
    },
    {
        name: 'class',
        type: 'reference object',
        description: 'The class category.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identification number.',
            },
            {
                name: 'name',
                type: 'string',
                description: 'The name of the object.',
            }
        ]
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'The location group\'s active status.'
    },
];
