import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const deletePluginInfoRequest: RequestExample = {
    title: {
        verb: 'DELETE',
        endpointUrl: '/api/integrations/plugin-info/:id',
    },
    curl:
        `curl --location \\
--request DELETE '<YOUR SERVER>/api/integrations/plugin-info/:id' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const deletePluginInfoResponse: Json = {
    title: 'RESPONSE',
    json: {},
};

const deletePluginParameters: Attribute[] = [
    {
        name: 'plugin',
        type: 'string',
        description: 'The name of the plugin.',
    },
    {
        name: 'authorization',
        type: 'string',
        description: 'The user specified code for authorization.',
    },
];

const data: RequestDefinitionData = {
    callName: 'delete-plugin-info',
    title: 'Delete a plugin information',
    description: 'Deletes the plugin information with the specified ID.',
    queryParameters: deletePluginParameters,
    attributes: [],
    requestObject: deletePluginInfoRequest,
    responseObject: deletePluginInfoResponse,
}

export default data;
