import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const searchManufactureOrdersRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/manufacture-orders',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/manufacture-orders' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};

const searchManufactureOrdersResponse: Json = {
    title: 'RESPONSE',
    json: {
        'totalCount': 46,
        'totalPages': 23,
        'pageNumber': 1,
        'pageSize': 2,
        'results': [
            {
                'id': 1,
                'number': '1002',
                'bomNumber': 'FG2100',
                'bomDescription': 'Extreme Mountain Bike',
                'soNumber': '',
                'dateScheduled': '2020-07-12T18:09:26.427-0600',
                'status': 'Entered',
                'locationGroup': 'SLC'
            },
            {
                'id': 2,
                'number': '3',
                'bomNumber': 'TB-1000',
                'bomDescription': 'Value Touring  Bike',
                'soNumber': '',
                'dateScheduled': '2020-08-16T18:09:26.601-0600',
                'status': 'Fulfilled',
                'locationGroup': 'SLC'
            }
        ]
    },
};

const searchManufactureOrdersQueryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'moNumber',
        type: 'string',
        description: 'The manufacture order number.',
    },
    {
        name: 'status',
        type: 'string: \'All\' | \'All Open\' | \'Entered\' | \'Issued\' | \'Partial\' | \'Fulfilled\' | \'Closed Short\' | \'Void\'',
        description: 'The order status.',
    },
    {
        name: 'bomNumber',
        type: 'string',
        description: 'A BOM number used in a manufacture order.',
    },
    {
        name: 'soNumber',
        type: 'string',
        description: 'The linked Sales Order number for a manufacture order.',
    },
    {
        name: 'assignedUserId',
        type: 'integer',
        description: 'The unique identifier of the user assigned to a manufacture order.',
    },
    {
        name: 'locationGroupId',
        type: 'integer',
        description: 'The unique identifier of a manufacture order\'s location group.',
    },
    {
        name: 'woCategory',
        type: 'string',
        description: 'The work order category name.',
    },
    {
        name: 'issuedFrom',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The start issued date cutoff for the search.'
    },
    {
        name: 'issuedTo',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The end issued date cutoff for the search.'
    },
    {
        name: 'scheduledFrom',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The start scheduled date cutoff for the search.'
    },
    {
        name: 'scheduledTo',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The end scheduled date cutoff for the search.'
    },
    {
        name: 'fulfilledFrom',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The start fulfilled date cutoff for the search.'
    },
    {
        name: 'fulfilledTo',
        type: 'date: \'yyyy-MM-dd\'',
        description: 'The end fulfilled date cutoff for the search.'
    },
    {
        name: 'containingPartNumber',
        type: 'string',
        description: 'A part number contained within a manufacture order.',
    },
    {
        name: 'containingPartDescription',
        type: 'string',
        description: 'A part description contained within a manufacture order.',
    },
    {
        name: 'containingBomItemType',
        type: 'string: \'All\' | \'Finished Good\' | \'Raw Good\' | \'Repair\' | \'Note\' | \'Bill of Materials\'',
        description: 'The type of a BOM item contained in the manufacture order',
    },
];

const searchManufactureOrdersAttributes: Attribute[] = [
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the order\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the custom field.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.'
            },
        ]
    }
];

const data: RequestDefinitionData = {
    callName: 'search-manufacture-order',
    title: 'Search for manufacture orders',
    description: 'Searches for manufacture orders.',
    attributes: searchManufactureOrdersAttributes,
    requestObject: searchManufactureOrdersRequest,
    responseObject: searchManufactureOrdersResponse,
    queryParameters: searchManufactureOrdersQueryParameters,
};

export default data;
