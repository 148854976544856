import {Attribute} from '../../components/ObjectAttribute';
import {Endpoint} from '../../components/Endpoints';
import {HeaderData} from '../../components/ObjectHeader';
import {Json} from '../../components/JsonObjectCard';

const partEndpoints: Endpoint[] = [
    {
        type: 'GET',
        endpoint: '/parts',
        link: '#search-part',
        tooltip: 'Search for parts',
    },
    {
        type: 'GET',
        endpoint: '/parts/:id/best-cost',
        link: '#get-part-best-cost',
        tooltip: 'Get the best cost for the part',
    },
];

export const partHeader: HeaderData = {
    title: 'Part',
    description: 'This is an object representing a Fishbowl part.',
    linkName: 'Part',
    link: 'https://www.fishbowlinventory.com/wiki/Part',
    endpoints: partEndpoints,
};

export const jsonObject: Json = {
    title: 'THE PART OBJECT',
    json: {}
};

export const partAttributes: Attribute[] = [];
