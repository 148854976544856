import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import ObjectDefinition from '../ObjectDefinition';
import {jsonObject, purchaseOrderAttributes, purchaseOrdersHeader} from '../../data/purchaseOrders/purchaseOrder';
import getPurchaseOrderRequest from '../../data/purchaseOrders/getPurchaseOrderRequest';
import createPurchaseOrderRequest from '../../data/purchaseOrders/createPurchaseOrderRequest';
import issuePurchaseOrderRequest from '../../data/purchaseOrders/issuePurchaseOrderRequest';
import unissuePurchaseOrderRequest from '../../data/purchaseOrders/unissuePurchaseOrderRequest';
import voidPurchaseOrderRequest from '../../data/purchaseOrders/voidPurchaseOrderRequest';
import closeShortPurchaseOrdersRequest from '../../data/purchaseOrders/closeShortPurchaseOrdersRequest';
import closeShortPurchaseOrderItemRequest from '../../data/purchaseOrders/closeShortPurchaseOrderItemRequest';
import deletePurchaseOrderRequest from '../../data/purchaseOrders/deletePurchaseOrderRequest';
import searchPurchaseOrdersRequest from '../../data/purchaseOrders/searchPurchaseOrdersRequest';
import updatePurchaseOrderRequest from '../../data/purchaseOrders/updatePurchaseOrderRequest';
import RequestDefinition from "../RequestDefinition";

export default new RouteComponent(`${apiRoutePrefix}/purchase-orders`, () => {
    const requests = [
        searchPurchaseOrdersRequest,
        getPurchaseOrderRequest,
        createPurchaseOrderRequest,
        updatePurchaseOrderRequest,
        issuePurchaseOrderRequest,
        unissuePurchaseOrderRequest,
        closeShortPurchaseOrdersRequest,
        closeShortPurchaseOrderItemRequest,
        voidPurchaseOrderRequest,
        deletePurchaseOrderRequest,
    ];
    return (
        <div>
            <ObjectHeader data={ purchaseOrdersHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <ObjectDefinition data={ {
                title: 'The purchase order object',
                attributes: purchaseOrderAttributes,
                jsonObject: jsonObject
            } }/>

            {
                requests.map(data => (
                    <>
                        <Box mb={3}>
                            <Divider/>
                        </Box>
                        <RequestDefinition {...data} />
                    </>
                ))
            }
        </div>
    );
});
