import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const getMemoRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/<OBJECT ENDPOINT>/:id/memos/:memoId',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/<OBJECT ENDPOINT>/:id/memos/:memoId' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const getMemosResponse: Json = {
    title: 'RESPONSE',
    json: {
        "id": 42,
        "memo": "This is a memo",
        "username": "admin",
        "dateCreated": "2021-02-26T13:59:53.171-0700"
    }
};
const data: RequestDefinitionData = {
    callName: 'get-memo',
    title: 'Get a memo',
    description: 'Retrieves the details of an existing memo. You only need to provide the unique memo ID.',
    attributes: [],
    requestObject: getMemoRequest,
    responseObject: getMemosResponse,
};

export default data;
