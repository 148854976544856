import {Attribute} from '../../components/ObjectAttribute';
import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';

const searchInventoryRequest: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/parts/inventory',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/parts/inventory' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>'
`,
};
const searchInventoryResponse: Json = {
    title: 'RESPONSE',
    json: {
        "totalCount": 4,
        "totalPages": 1,
        "pageNumber": 1,
        "pageSize": 100,
        "results": [
            {
                "id": 28,
                "partNumber": "B201",
                "quantity": "242",
                "partDescription": "Premium Brake Cables",
                "uom": {
                    "id": 1,
                    "name": "Each",
                    "abbreviation": "ea"
                }
            },
            {
                "id": 36,
                "partNumber": "C201",
                "quantity": "46",
                "partDescription": "Single Speed Chain",
                "uom": {
                    "id": 1,
                    "name": "Each",
                    "abbreviation": "ea"
                }
            },
            {
                "id": 40,
                "partNumber": "F201",
                "quantity": "50",
                "partDescription": "Road Fury Frame",
                "uom": {
                    "id": 1,
                    "name": "Each",
                    "abbreviation": "ea"
                }
            },
            {
                "id": 72,
                "partNumber": "RF201",
                "quantity": "56",
                "partDescription": "Various Reflectors",
                "uom": {
                    "id": 1,
                    "name": "Each",
                    "abbreviation": "ea"
                }
            }
        ]
    },
};

const searchInventoryQueryParameters: Attribute[] = [
    {
        name: 'pageNumber',
        type: 'integer',
        description: 'The current page of the results.'
    },
    {
        name: 'pageSize',
        type: 'integer',
        description: 'The number of returned results per page. (Default 100)'
    },
    {
        name: 'number',
        type: 'string',
        description: 'The part number.',
    },
    {
        name: 'description',
        type: 'string',
        description: 'The part description.',
    },
    {
        name: 'locationId',
        type: 'integer',
        description: 'The unique identifier for the location.',
    },
    {
        name: 'locationGroupId',
        type: 'integer',
        description: 'The unique identifier for the location group.',
    },
    {
        name: 'abc',
        type: 'string: \'A\' | \'B\' | \'C\'',
        description: 'The ABC code for the part.',
    },
    {
        name: 'includeZeroQuantities',
        type: 'boolean',
        description: 'Indicates whether to include parts with no on hand inventory.',
    },
    {
        name: 'active',
        type: 'boolean',
        description: 'Indicates whether to only include active parts.',
    },
];

const searchInventoryAttributes: Attribute[] = [
    {
        name: 'customFields',
        type: 'list',
        description: 'A list of the part\'s custom fields.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the custom field.',

            },
            {
                name: 'value',
                type: 'string',
                description: 'The custom field\'s value.',
            },
        ]
    },
    {
        name: 'trackingItems',
        type: 'list',
        description: 'A list of the tracking items associated with a part.',
        children: [
            {
                name: 'id',
                type: 'integer',
                description: 'The unique identifier for the tracking item.',
            },
            {
                name: 'trackingValue',
                type: 'string',
                description: 'The value of the tracking item to be searched. Date type tracking are a ranged filter' +
                    ' using the following format: yyyy-MM-dd - yyyy-MM-dd',
            },
        ]

    }
];

const data: RequestDefinitionData = {
    callName: 'search-inventory',
    title: 'Search for inventory',
    description: 'Searches for inventory.',
    attributes: searchInventoryAttributes,
    requestObject: searchInventoryRequest,
    responseObject: searchInventoryResponse,
    queryParameters: searchInventoryQueryParameters,
}

export default data;
